import {
    AlertColor,
    Box,
    Grid,
    Stack,
    Typography,
    Tooltip,
    IconButton,
    Skeleton,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { InfinitySpin } from "react-loader-spinner";
import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import { mcqGenerateApi, technicalInterviewApi, technicalInterviewSolutionApi } from "../../api_config/api_services";
import Form from "./Form";
import QuestionsBoxButtons from "../Mockinterview/InterviewQues/QuestionsBoxButtons";
import InfoIcon from "@mui/icons-material/Info";
import T from "../../utils/T";
import { useSearchParams } from "react-router-dom";
import { store } from "../../api_config/store";
import { setData } from "../../api_config/slices/technicalQuestionSlice";
import QuestionComponent from "./QuestionComponent";
import { useDispatch, useSelector } from "react-redux";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import InstructionsModal from "../Mockinterview/InterviewQues/InstructionsModal";
import Footer from "../footer/Footer";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";

function TechnicalInterview() {
    // const [files, setFile] = useState<any>([]);
    const [isChatEnabled, setChatEnabled] = useState(false);
    const [query, setQuery] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<any>(null);
    const [isSideVisible, setSideVisible] = useState(false);
    const [isFormVisible, setisFormVisible] = useState(true);
    const [responseMessage, setResponseMessage] = useState<{
        type: AlertColor | undefined;
        message: string | undefined;
        isVisible: boolean;
    }>();

    const [openInstructionModal, setOpenInstructionModal] = useState(true);

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    // Use the useSelector hook to access the questionDict from the Redux store
    const questionDict = useSelector((state: any) => state.data?.questionDict)
    const solutionDict = useSelector((state: any) => state?.data?.solutionDict?.solutionDict);
    const Experience = useSelector((state: any) => state?.data?.experience);
    const EmployeeId = useSelector((state: any) => state?.data?.employeeId);

    const Question = "Question Dict"

    useEffect(() => {
        return () => {
            store.dispatch(setData({
                experience: '',
                questionDict: {},
                employeeId: '',
                solutionDict: {}
            }));
        }
    }, [])


    const handleInstructionModal = () => {
        setOpenInstructionModal(!openInstructionModal)
    }

    const handleCallback = (result: any) => {
        const notRequiredData = ['username', 'time', 'name']
        const payload: any = {}
        if (result && Object.keys(result).length > 0) {
            Object.keys(result).filter((item: any) => {
                if (!notRequiredData.includes(item)) {
                    payload[item] = result[item];
                }
            })
        }

        handleUpload(payload)
    }

    const handleUpload = async (payload: any) => {

        const Data: any = {
            emp_id: payload?.employeeid,
            YOE: payload?.experience,
            tech_stack: payload?.techstack,
            topics: payload?.topics
        };


        const req = JSON.stringify(Data)
        setOpen(true)
        await technicalInterviewApi(req).then((result: any) => {
            const technicalInterviewAPIData = result?.data;
            if (result?.success) {
                setResponseMessage({
                    type: "success",
                    message: "Success",
                    isVisible: true,
                });
                // setMessage(technicalInterviewAPIData);
                store.dispatch(setData({
                    experience: technicalInterviewAPIData?.Experience,
                    questionDict: technicalInterviewAPIData && technicalInterviewAPIData[Question],
                    employeeId: technicalInterviewAPIData?.employee_id
                }));

                // setChatEnabled(true)
                // setSideVisible(true)
                setisFormVisible(false)
                setOpen(false);

            }
            else {
                const message: string = result.data?.response?.data?.error || "Something Went Wrong."
                notifyError(message);
                setOpen(false);

            }

        }).catch((err: any) => {
            const error = err as AxiosError;

            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            setResponseMessage({
                type: "error",
                message: errorMessage,
                isVisible: true,
            });
            setOpen(false);
        })

    };


    const handleSubmit = async () => {
        const Data = {
            emp_id: EmployeeId,
            experience: `${Experience} years`,
            Question_dict: questionDict,
            solution_dict: solutionDict

        };
        const req = JSON.stringify(Data)
        setOpen(true);
        await technicalInterviewSolutionApi(req).then((result) => {
            const technicalInterviewSolutionAPIData = result;

            setMessage(technicalInterviewSolutionAPIData);
            setChatEnabled(true)
            setisFormVisible(false)
            setOpen(false);

        }).catch((err) => {
            const error = err;

            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data.error;
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            // setResponseMessage({
            //     type: "error",
            //     message: errorMessage,
            //     isVisible: true,
            // });
            setOpen(false);
        })

    };

    const notifyError = (message: string) => toast.error(message);

    const items = [
        { id: 1, text: "Employee Recruitment and Screening" },
        { id: 2, text: "Customer Support and Technical Assistance Training" },
        { id: 3, text: "Onboarding and Training Programs" },
        { id: 4, text: "Internal Skill Gap Analysis" },
        { id: 5, text: "Employee Promotion and Career Pathing" },
        { id: 6, text: "Freelancer and Contractor Evaluation" },
        { id: 7, text: "Academic Admissions and Placement Tests" },
        { id: 8, text: "Certification and Credentialing" },
        { id: 9, text: "Corporate Training and Development" },
        { id: 10, text: "Talent Management and Succession Planning" },
        { id: 11, text: "Workforce Upskilling and Reskilling Initiatives" },
        { id: 12, text: "Hackathons and Coding Competitions" },
        { id: 13, text: "Remote Work Assessments" },
        { id: 14, text: "Project Team Assembly and Role Assignment" },
        { id: 15, text: "Educational Course Assessments" },
        { id: 16, text: "Compliance and Regulatory Training" },
        { id: 17, text: "Performance Review and Appraisal Systems" },
        { id: 18, text: "Software and IT Service Vendor Evaluation" },
        { id: 19, text: "Internship and Apprenticeship Programs" },
        { id: 20, text: "Research and Development Skill Assessments" },
    ]

    const CaseStudies = [
        {
            id: 1,
            title: "Streamlining Compliance",
            description: "A regulatory agency implemented our technical skills tool to ensure compliance with industry standards and regulations. By verifying employees' technical competencies, they minimized compliance risks, avoided penalties, and upheld their reputation for regulatory excellence."
        },
        {
            id: 2,
            title: "Scaling IT Operations",
            description: "A rapidly growing startup used our technical skills tool to manage their expanding IT department. By efficiently assessing and developing their IT staff's skills, they maintained high service levels, avoided downtime, and seamlessly scaled their operations to meet increased demand."
        },
        {
            id: 3,
            title: "Strengthening Cybersecurity",
            description: "A financial services firm employed our technical skills tool to assess their IT team's cybersecurity knowledge. By identifying gaps and providing targeted training, they significantly enhanced their defense against cyber threats, reducing the risk of data breaches and financial losses."
        },
    ]

    return (
        <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
            {/* Header */}

            <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
                <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
                    {/* Horizontal List */}
                    <HorizontalList items={items} />

                    {/* Chat Container */}
                    {/* <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
              > */}
                    <Grid item sm={8} lg={12} borderRadius={'8px'}>
                        <>
                            <ToastContainer position="bottom-right" autoClose={2000} />

                            <Grid container height={'78.1vh'}>

                                <Grid
                                    item
                                    md={12}
                                    display="flex"
                                    className="grid-right-pane"
                                    sx={{
                                        backgroundColor: "white",
                                        borderColor: "lightgrey",
                                        borderRadius: '8px'
                                        // paddingTop: "2rem",
                                    }}
                                    justifyContent="center"
                                >
                                    {isChatEnabled ? (
                                        <Box
                                            display="flex" flexDirection="column" width="90%" sx={{ m: 2 }}
                                        >

                                            <Grid
                                                item
                                                className="questionDiv"
                                                sx={{
                                                    whiteSpace: 'pre-line'
                                                    // position: "absolute",
                                                    // top: "50%",
                                                    // left: "50%",
                                                    // transform: "translate(-50%, -50%)",
                                                    // minHeight: "270px",
                                                    // width: "inherit",
                                                    // minWidth: "300px",
                                                    // maxHeight: "650px",
                                                    // backgroundColor: "background.white",
                                                    // borderRadius: "8px",
                                                    // padding: "50px",
                                                    // display: "flex",
                                                    // flexDirection: "column",
                                                    // justifyContent: "space-between",
                                                    // boxShadow: "3px 3px 30px -10px rgba(0,0,0,0.3)",
                                                    // overflowY: "auto",
                                                }}
                                            >
                                                {message && (
                                                    <div  >
                                                        <Stack>
                                                            <Typography
                                                                variant="h6"
                                                                sx={{
                                                                    textAlign: "center",
                                                                    fontSize: "1.6rem",
                                                                    fontWeight: "bold",
                                                                    mb: 1.5,
                                                                    color: NETSMARTZ_THEME_COLOR
                                                                }}
                                                            >
                                                                Result
                                                            </Typography>
                                                            <Box sx={{ alignItems: "center" }}>
                                                                <Typography
                                                                    // variant="subtitle-2"
                                                                    sx={{
                                                                        flexWrap: "wrap",
                                                                        textAlign: "center",
                                                                        color: "black",
                                                                        marginBottom: 1,
                                                                    }}
                                                                >
                                                                    {`${message?.Result ? message?.Result : 'Error:-Result Not Fetched'}`}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box display={"flex"} flexDirection={'column'} justifyContent="center" alignItems={'center'}>
                                                {isFormVisible ?
                                                    <>

                                                        {/* Display Conversation Messages */}
                                                        <div className="pdf-container1" >
                                                            <Box >
                                                                <Form
                                                                    handleCallback={handleCallback}
                                                                    formTitle="Please Complete The Following Fields to Generate Technical Questions"
                                                                />
                                                            </Box>

                                                        </div>


                                                    </>
                                                    :
                                                    <QuestionComponent handleSubmit={handleSubmit} />
                                                }
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                            </Grid>


                            <Grid item xs={3.25} >
                                {openInstructionModal && (
                                    <InstructionsModal openInstructionModal={openInstructionModal} handleInstructionModal={handleInstructionModal} />
                                )}
                            </Grid>

                            {open ? (
                                <div className="spinner-container" >
                                    <InfinitySpin width="200" color="#F86F03" />
                                </div>
                            ) : null}

                        </>
                    </Grid>
                    {/* </Grid> */}
                </Grid>

                {/* Vertical List of Cards */}
                <VerticalCardList items={CaseStudies} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default TechnicalInterview;
