import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, FormControlLabel, Radio, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ParagraphIcon from '@mui/icons-material/Subject'; // Example icons
import BulletListIcon from '@mui/icons-material/FormatListBulleted';

const Modal = ({ open, onClose, ImageOption, onChange, onSubmitClick, InputLanguage, handleInputLang, inputlanguages }: any) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h5" component="div">
                    Select Option
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1" sx={{ mb: 2 }}>
                    Does your document include any images?
                </DialogContentText>
                <RadioGroup value={ImageOption} onChange={onChange}>
                    <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={
                            <Box display="flex" alignItems="center">
                                <ParagraphIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">Yes</Typography>
                            </Box>
                        }
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={
                            <Box display="flex" alignItems="center">
                                <BulletListIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">No</Typography>
                            </Box>
                        }
                    />
                </RadioGroup>
                {ImageOption === 'yes' &&
                <Box sx={{ display: "flex", flexDirection: 'row', mb: 5, ml: 5.5, width: '90%' }}>
                    <FormControl variant="filled" sx={{ flex: 1, mx: 1, mt: 2 }}>
                        <InputLabel id="input-language-select-label">Input Language</InputLabel>
                        <Select
                            sx={{
                                width: '85%',
                                boxShadow: 10,
                                borderRadius: 2,
                                backgroundColor: '#f7f7f7',
                            }}
                            labelId="input-language-select-label"
                            id="input-language-select"
                            value={InputLanguage}
                            label="Input Language"
                            onChange={handleInputLang}
                        >
                            {Object.entries(inputlanguages).map(([language, code]) => (
                                <MenuItem value={language}>
                                    {language}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                }
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmitClick} color="primary" variant="contained">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
