import { createSlice } from '@reduxjs/toolkit';

// Define the initial state based on the provided data structure
const initialState = {
    token: undefined,
    username: undefined,
    // tags:[]
};

// Create the slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Define a reducer to update the state with new data
        setAuthToken(state, action) {
            console.log(action.payload, 'action.payload')
            const { tokens, email, tags } = action.payload;
            state.token = tokens?.access;
            state.username = email;
            // state.tags = tags;
        },
        // You can add more reducers here for other actions if needed
    }
});

// Export the actions and reducer
export const { setAuthToken } = authSlice.actions;
export const authReducer = authSlice.reducer;
