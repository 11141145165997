import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'PostgreSQL',
    'MySQL',
    'SQLite',
    'MongoDB',
    'Microsoft SQL Server',
    'Oracle Database',
    'IBM Db2',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const User = () => {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        schema: '',
        databaseURL: '',
        instruct: '',
        question: '',
        savedLink: '',
    });
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTextFieldValue, setModalTextFieldValue] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleDatabaseURLChange = (event) => {
        const { value } = event.target;
        if (value) {
            setIsModalOpen(true);
        }
        setFormData({
            ...formData,
            databaseURL: value,
        });
    };

    const handleSaveLink = () => {
        setFormData({
            ...formData,
            savedLink: modalTextFieldValue,
        });
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        console.log(formData);
    };

    const { schema, databaseURL, instruct, question, savedLink } = formData;

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <TextareaAutosize
                    aria-label="Schema"
                    placeholder="Schema"
                    name="schema"
                    value={schema}
                    onChange={handleChange}
                    minRows={3}
                    maxRows={6}
                    style={{
                        width: '95%',
                        resize: 'none',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        fontSize: '16px',
                        lineHeight: '2.5',
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-name-label">DatabaseURL</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="databaseURL"
                        value={databaseURL}
                        onChange={handleDatabaseURLChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                        style={{ width: '100%' }}
                    >
                        {names.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, databaseURL, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                        <MenuItem value="Other">Other (Paste URL)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Instruct"
                    id="instruct"
                    name="instruct"
                    value={instruct}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Question"
                    id="question"
                    name="question"
                    value={question}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                {
                    databaseURL && (
                        <Grid item xs={4}>
                        <TextField label="Saved Link" value={savedLink} variant="outlined"  fullWidth/>
                        </Grid>
                    )
                }
            </Grid>
            {databaseURL && (
                <Modal
                    open={isModalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <TextField
                            label="Paste Database URL"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={modalTextFieldValue}
                            onChange={(e) => setModalTextFieldValue(e.target.value)}
                        />
                        <Button onClick={handleSaveLink} variant="contained" sx={{ mt: 2 }}>
                            Save
                        </Button>
                        <Button onClick={handleModalClose} variant="contained" sx={{ mt: 2, ml: 2 }}>
                            Close
                        </Button>
                    </Box>
                </Modal>
            )}
            <Grid item xs={4} marginRight='100px' >
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
};

export default User;
