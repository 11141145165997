import { MicOff as MicOffIcon } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";

interface SpeechRecognitionDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onStopListening: () => void;
    // onSubmit: () => void;
    transcript: string;
    listening: boolean;
}

const SpeechRecognitionDialog: React.FC<SpeechRecognitionDialogProps> = ({
    isOpen,
    onClose,
    onStopListening,
    // onSubmit,
    transcript,
    listening,
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent>
                <Box display={"flex"} justifyContent={'center'} gap={2}>
                    <Typography variant="h6" gutterBottom>
                        Listening
                    </Typography>
                    {listening ? (
                        <ThreeDots height={30} width={30} color={NETSMARTZ_THEME_COLOR} />
                    ) : null}
                </Box>
                <Typography variant="body1" paragraph>
                    {transcript}
                </Typography>
                <Box textAlign={"center"}>
                    <Button
                        startIcon={<MicOffIcon />}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={onStopListening}
                        sx={{
                            textAlign: "center",
                            boxShadow: 0,
                            color: "white",
                            borderRadius: 1.5,
                            padding: "8px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            textTransform: "capitalize",
                            fontWeight: 600,
                        }}
                    >
                        Stop Recording
                    </Button>

                    {/* <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={onSubmit}
                        sx={{
                            marginLeft: "20px",
                            boxShadow: 0,
                            borderRadius: 1.5,
                            color: 'white'
                        }}
                    >
                        {/* Upload file */}
                    {/* Submit */}
                    {/* </Button> */}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SpeechRecognitionDialog;
