import React from "react";
import {
    withStyles,
    Card,
    CardContent,
    CardActions,
    MenuItem,
    Button
} from "@material-ui/core";
import { withFormik } from "formik";
import * as yup from "yup";
import { validationsForm, TechnicalForm } from "../../utils/validations/validationSchema";
import { StyleRules } from "@material-ui/core/styles";
import { TextField, Typography, colors } from "@mui/material";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";

const styles = (): StyleRules => ({
    card: {
        maxWidth: 420,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 2,
    },
});

const fieldStyle: any = {
    fontSize: '0.600rem',
    marginBottom: '8px',
    backgroundColor: '#F7F7F7',
    borderRadius:'8px',
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            opacity:0.1
        },
    }
}

const form = (props: any) => {
    const {
        classes,
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        isCodeEval,
        formTitle
    } = props;

    return (
        <div className={classes.container}>
            <form onSubmit={handleSubmit}>
                <Card style={{ width: 600, padding: 10 }}>
                    <CardContent>
                        {formTitle && <Typography align="center" sx={{ m: 2 }} variant="h6">{formTitle}</Typography>}
                        <TextField
                            id="employeeid"
                            label="Employee ID"
                            value={values.employeeid}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.employeeid ? errors.employeeid : ""}
                            error={touched.employeeid && Boolean(errors.employeeid)}
                            variant="outlined"
                            fullWidth
                            sx={fieldStyle}
                        />
                        <TextField
                            id="experience"
                            label="Experience in years"
                            value={values.experience}
                            type="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.experience ? errors.experience : ""}
                            error={touched.experience && Boolean(errors.experience)}
                            variant="outlined"
                            fullWidth
                            sx={fieldStyle}
                        />
                        <TextField
                            id="techstack"
                            label="Tech Stack"
                            value={values.techstack}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.techstack ? errors.techstack : ""}
                            error={touched.techstack && Boolean(errors.techstack)}
                            variant="outlined"
                            fullWidth
                            sx={fieldStyle}
                        />
                        <TextField
                            id="topics"
                            label="Topics"
                            value={values.topics}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.topics ? errors.topics : ""}
                            error={touched.topics && Boolean(errors.topics)}
                            variant="outlined"
                            fullWidth
                            sx={fieldStyle}
                        />

                        {isCodeEval && (
                            <>
                                <TextField
                                    id="username"
                                    label="Username"
                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.username ? errors.username : ""}
                                    error={touched.username && Boolean(errors.username)}
                                    variant="outlined"
                                    fullWidth
                                    sx={fieldStyle}
                                />
                                <TextField
                                    id="name"
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.name ? errors.name : ""}
                                    error={touched.name && Boolean(errors.name)}
                                    variant="outlined"
                                    fullWidth
                                    sx={fieldStyle}
                                />
                                <TextField
                                    id="time"
                                    label="Total Time in Minutes"
                                    value={values.time}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.time ? errors.time : ""}
                                    error={touched.time && Boolean(errors.time)}
                                    variant="outlined"
                                    fullWidth
                                    sx={fieldStyle}
                                />
                            </>
                        )}
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                            style={{
                                textAlign: "center",
                                display: "flex",
                                width: "100%",
                                backgroundColor: NETSMARTZ_THEME_COLOR,
                                color: "white",
                                borderRadius: 5,
                                textTransform: "capitalize",
                                fontWeight: 600,
                            }}
                        >
                            SUBMIT
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleReset}
                            style={{
                                textAlign: "center",
                                display: "flex",
                                width: "100%",
                                backgroundColor: 'black',
                                color: "white",
                                borderRadius: 5,
                                textTransform: "capitalize",
                                fontWeight: 600,
                            }}
                        >
                            CLEAR
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    );
};


const schemaObj = (props: any) => props.isCodeEval ? validationsForm : TechnicalForm;

const Form = withFormik({
    mapPropsToValues: ({
        employeeid,
        experience,
        techstack,
        topics,
        username,
        name,
        time
    }: any) => {
        return {
            employeeid: employeeid || "",
            experience: experience || "",
            techstack: techstack || "",
            topics: topics || "",
            username: username || "",
            name: name || "",
            time: time || ""
        };
    },

    validationSchema: (props: any) => yup.object().shape(schemaObj(props)),

    handleSubmit: (values, { setSubmitting, props }) => {
        const result = JSON.parse(JSON.stringify(values))
        result.topics = result?.topics.split(",")
        setSubmitting(false);
        props.handleCallback(result);
    }
})(form);

export default withStyles(styles)(Form);
