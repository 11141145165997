import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { AxiosError } from "axios";
import { useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "react-circular-progressbar/dist/styles.css";
import './style.scss'
import { InfinitySpin } from "react-loader-spinner";
// import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { multiTransalationAudioUploadApi, multiTransalationDocUploadApi, multiTransalationTextApi, transalationAudioUploadApi, transalationRecordingApi } from "../../api_config/api_services";
import { FileUploader } from "react-drag-drop-files";
import JapaneseToEnglishDialog from "./JapaneseToEnglishDialog";
import SelectedFiles from "../common/SelectedFiles";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";
import { useSelector } from "react-redux";
import Translation from '../../assets/images/translation.png';
import Upload from '../../assets/images/upload.png';
import Mic from '../../assets/images/Mic.png';
import Modal from "./Modal";


function MultiTranslation() {
  const [files, setFile] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [englishConversion, setEnglishConversion] = useState<any>(null);
  const [subType, setSubType] = useState("Japanese-to-English");
  // const [selectedLanguage, setSelectedLanguage] = useState<any>('');
  // const [selectedLanguageCode, setSelectedLanguageCode] = useState<any>('');
  const [OutputLanguage, setOutputLanguage] = useState<any>('');
  const [InputLanguage, setInputLanguage] = useState<any>('');
  const [OutputLanguageCode, setOutputLanguageCode] = useState<any>("");
  const [IutputLanguageCode, setIutputLanguageCode] = useState<any>('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [audioRecording, setAudioRecording] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [ImageOption, setImageOption] = useState('no'); // State to manage view mode
  const [type, setType] = useState<any>(false);
  // const [uploadStatus, setUploadStatus] = useState<string | null>(null);


  const fileTypes: string[] = ["MP3", "AAC", "FLAC", "WAV", "AIFF", "MP4", "PDF"];
  const [audioFile, setAudioFile] = useState<any>([]);

  const [isSideVisible, setSideVisible] = useState(true);
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertColor | undefined;
    message: string | undefined;
    isVisible: boolean;
  }>();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();


  const items = [
    { id: 1, text: 'Multilingual Customer Support' },
    { id: 2, text: 'Multilingual Website Translation' },
    { id: 3, text: 'Language Learning Platforms' },
    { id: 4, text: 'E-commerce Product Descriptions Translation' },
    { id: 5, text: 'Social Media Content Translation' },
    { id: 6, text: 'Cross-Cultural Marketing Campaigns' },
    { id: 7, text: 'Media and Entertainment Subtitling' },
    { id: 8, text: 'Legal Document Translation' },
    { id: 9, text: 'Medical Record Translation' },
    { id: 10, text: 'Travel and Tourism Applications' },
    { id: 11, text: 'International Journalism and News Agencies' },
    { id: 12, text: 'International Conference Interpretation' },
    { id: 13, text: 'Academic Research Translation' },
  ]

  const CaseStudies = [
    {
      id: 1,
      title: "Enhancing Customer Reach for E-commerce Retailer",
      description: "An e-commerce retailer wanted to expand its reach to non-English speaking countries. By integrating our AI translation tool, they translated their entire product catalog and customer service communications into 10 different languages. This led to a 30% increase in international sales and a significant reduction in customer support response times, improving overall customer satisfaction."
    },
    {
      id: 2,
      title: "Streamlining Tech Support for a Global Tech Firm",
      description: "A tech firm struggled with providing timely customer support across various regions. Our AI translation tool enabled their support team to handle queries in multiple languages instantly. This resulted in faster resolution times and a more personalized customer experience, leading to a 25% improvement in customer retention rates."
    },
    {
      id: 3,
      title: "Localizing Content for a Global Educational Platform",
      description: "An online education platform aimed to provide its courses to a global audience. Using our AI translation tool, they translated course materials, quizzes, and interactive content into 15 languages. This increased enrollment rates by 40% from non-English speaking countries, making quality education accessible to a broader audience."
    }
  ]

  const notifyError = (message: string) => toast.error(message);

  const multiTransalationAudioUploadApiCall = async (formData: any) => {
    setLoading(true)
    await multiTransalationAudioUploadApi(formData).then((result: any) => {
      if (result?.success) {
        setEnglishConversion(result?.data);
        setChatEnabled(true);
        setAudioFile([])
        setFile([])
        setOpen(false);
        setLoading(false)
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);
        setLoading(false);
      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }


  const multiTransalationDocUploadApiCall = async (formData: any) => {
    setLoading(true)
    await multiTransalationDocUploadApi(formData).then((result: any) => {
      if (result?.success) {
        setEnglishConversion(result?.data);
        setChatEnabled(true);
        setAudioFile([])
        setFile([])
        setOpen(false);
        setLoading(false)
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);
        setLoading(false);
      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }

  const handleAudioUpload = async () => {
    // Check if input and output language codes are selected
    if (OutputLanguageCode === '') {
      notifyError("Please select output language.");
      return;
    }

    // Check if only one file is selected
    if (audioFile.length > 1) {
      notifyError("Only one file allowed at a time.");
      return;
    }

    // Check if any of the files are empty
    const emptyFile = audioFile.find((file: File) => file.size === 0);
    if (emptyFile) {
      notifyError("The uploaded file has no content.");
      return;
    }

    // Check if any of the files are empty
    const largeFile = audioFile.find((file: File) => console.log(file.size >= 22));
    if (largeFile) {
      notifyError("The uploaded file is too large.");
      return;
    }

    // Check if audioRecording is empty
    if (audioRecording && audioRecording.size === 0) {
      notifyError("The recorded audio has no content.");
      return;
    }



    setOpen(true);

    const formData = new FormData();

    // Append the audio file or recording to formData
    if (audioRecording) {
      formData.append('file', audioRecording, 'recording.wav');
    } else {
      audioFile.forEach((file: File) => {
        formData.append('file', file);
      });
    }

    // Append other required data to formData
    formData.append("target", OutputLanguageCode);
    formData.append("username", username);

    // Check the extension of the uploaded file
    const fileExtension = audioFile[0]?.name.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      // Call the API to upload the document
      formData.append("contains_image", type);
      IutputLanguageCode ? formData.append("src_lang", IutputLanguageCode) : formData.append("src_lang", 'en')
      multiTransalationDocUploadApiCall(formData);
    } else {
      // Call the API to upload the audio
      multiTransalationAudioUploadApiCall(formData);
    }
  };




  const handleUpload = async (query: any) => {
    if (OutputLanguageCode === '') {
      notifyError("Please select output language.");
      return;
    }
    if (query === '') {
      notifyError("Please add the Text for Translation.");
    }
    else {
      setLoading(true)
      let data = {
        text: query,
        target: OutputLanguageCode,
        username: username
      }
      const req = JSON.stringify(data)
      await multiTransalationTextApi(req).then((result: any) => {
        const responseData = result?.data;
        if (result?.success) {
          setResponseMessage({
            type: "success",
            message: "Success",
            isVisible: true,
          });
          setEnglishConversion(responseData);
          resetTranscript();
          setChatEnabled(true);
          setLoading(false);
        }
        else {
          const message: string = result?.data?.response?.data?.error || "Something Went Wrong."
          notifyError(message);
          setOpen(false);
          setLoading(false);
        }

      }).catch((err: any) => {
        const error = err as AxiosError;
        resetTranscript();

        // Consolidated error handling
        let errorMessage = "Something went wrong.";
        if (error.response) {
          const responseData = error.response.data as { error?: string };
          if (responseData?.error) {
            errorMessage = responseData.error;
          }
        } else {
          errorMessage = "Error occurred while setting up the request: " + error.message;
        }

        // Notify error and set response message
        notifyError(errorMessage);
        setResponseMessage({
          type: "error",
          message: errorMessage,
          isVisible: true,
        });
        setOpen(false);
        setLoading(false);
        notifyError(error.message || "something went wrong");
      })
    }
  };

  const { username } = useSelector((state: any) => state?.auth);

  // const sendAudioToAPI = async (audioBlob: any) => {
  //   setUploadStatus('Uploading...');
  //   const formData = new FormData();
  //   // formData.append('file', audioBlob, 'recording.wav');
  //   formData.append('file', audioBlob[0]);
  //   formData.append("target", 'plt_Latn');
  //   formData.append("username", 'NIS103');

  //   try {
  //     const response = await fetch('http://aibackend.netsmartz.us/api/get_file_multilang', {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //       body: formData,
  //     });

  //     if (response.ok) {
  //       setUploadStatus('Upload successful');
  //     } else {
  //       setUploadStatus('Upload failed');
  //     }
  //   } catch (error) {
  //     setUploadStatus('Upload error');
  //   }

  // };


  const handleMicButton = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    // handleStartListening();
    isRecording ? stopRecording() : startRecording()
  };

  // const handleStartListening = () => {
  //   SpeechRecognition.startListening({ continuous: true, language: `${subType === "Japanese-to-English" ? `ja` : `en-IN`}` });
  //   setIsDialogOpen(true);
  //   setIsListening(true);
  // };

  // const handleStopListening = () => {
  //   SpeechRecognition.stopListening();
  //   setQuery(transcript);
  //   resetTranscript();
  //   setIsListening(false);
  //   setIsDialogOpen(false);
  //   // handleUpload(transcript)
  // };


  const handleCloseDialog = () => {
    setIsDialogOpen(true);
  };

  const handleChange = (fileOrFiles: any) => {
    setAudioFile([fileOrFiles[0]]);
    setChatEnabled(false)
    setQuery('')
    setAudioRecording('')
    const fileExtension = fileOrFiles[0]?.name.split('.').pop().toLowerCase();

    if (fileExtension === 'pdf') {
      // Call the API to upload the document
      setModalOpen(true)
      // multiTransalationDocUploadApiCall(formData);
    }
  };
  const onDrop = (fileOrFiles: any) => {
    console.log("drop", fileOrFiles);
  };
  const onSelect = (fileOrFiles: any) => {
    setChatEnabled(false)
  };

  const onTypeError = (err = 1) => {
    console.log(err);
    notifyError(`${err}` || "File type is not supported.")

  }
  const onSizeError = (err = 1) => console.log(err);

  const handleDeletedFiles = (index: number) => {
    const arr = [...files];
    arr.splice(index, 1);
    setAudioFile(arr);
  };


  const startRecording = async () => {
    setAudioURL(null);
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording');
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event: BlobEvent) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      setAudioRecording(audioBlob)
      const audioURL = URL.createObjectURL(audioBlob);
      setAudioURL(audioURL);
      audioChunksRef.current = [];
    };

    mediaRecorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };



  // UI Sections
  const renderIsChatEnabled = () => {
    return (
      <Box display="flex" flexDirection="column" width="90%">
        <div className="pdf-container1">
          <Card sx={{ marginBottom: "10px", marginTop: '10px' }}>
            <CardContent>
              <Box display="flex" alignItems="center" marginBottom={3}>
                <IconButton onClick={() => {
                  setChatEnabled(false)
                  setQuery('')
                  setAudioRecording('')
                  setAudioFile([])
                  setAudioURL(null)
                }}
                  sx={{
                    backgroundColor: '#FEA349', // Change to your desired color
                    borderRadius: '50%',
                    marginRight: '20px'
                  }}
                >
                  <ArrowBackIcon sx={{ color: 'white' }} />
                </IconButton>
                <Typography textAlign={"center"} variant="h4">{"Translated Text"}</Typography>
              </Box>
              <Typography variant="body1">{englishConversion?.message}</Typography>
            </CardContent>
          </Card>
        </div>
      </Box >
    )
  }


  // const handleInputLang = (event: any) => {
  //   const language = event.target.value as any;
  //   setSelectedLanguage(language);
  //   setSelectedLanguageCode(languages[language]);
  // };

  const handleOutputLang = (event: any) => {
    const language = event.target.value as any;
    setOutputLanguage(language);
    setOutputLanguageCode(languages[language]);
  };

  const handleInputLang = (event: any) => {
    const language = event.target.value as any;
    setInputLanguage(language);
    setIutputLanguageCode(inputlanguages[language]);
  };

  // Open the modal
  const handleOpenModal = (index: any) => {
    setModalOpen(true);
  }

  // Close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setAudioFile([])
    setFile([])
  };

  // Handle Image Option selection
  const handleImageOptionChange = (event: any) => {
    setImageOption(event.target.value);
    if (event.target.value === 'yes') {
      setType(true);
    } else {
      setType(false);
    }
  };

  const handleSubmitClick = () => {
    // Handle form submission
    console.log('Submitted:', { ImageOption, InputLanguage });
    setOpen(false);
    setModalOpen(false);
  };


  const languages: { [key: string]: string } = {
    "Afrikaans": "afr_Latn",
    "Amharic": "amh_Ethi",
    "Modern Standard Arabic": "arb_Arab",
    "Assamese": "asm_Beng",
    "South Azerbaijani": "azb_Arab",
    "North Azerbaijani": "azj_Latn",
    "Belarusian": "bel_Cyrl",
    "Bulgarian": "bul_Cyrl",
    "Bengali": "ben_Beng",
    "Bosnian": "bos_Latn",
    "Catalan": "cat_Latn",
    "Czech": "ces_Latn",
    "Welsh": "cym_Latn",
    "Danish": "dan_Latn",
    "German": "deu_Latn",
    "Dzongkha": "dzo_Tibt",
    "Greek": "ell_Grek",
    "English": "eng_Latn",
    "Esperanto": "epo_Latn",
    "Spanish": "spa_Latn",
    "Estonian": "est_Latn",
    "Basque": "eus_Latn",
    "Western Persian": "pes_Arab",
    "Finnish": "fin_Latn",
    "French": "fra_Latn",
    "Irish": "gle_Latn",
    "Galician": "glg_Latn",
    "Gujarati": "guj_Gujr",
    "Hebrew": "heb_Hebr",
    "Hindi": "hin_Deva",
    "Croatian": "hrv_Latn",
    "Hungarian": "hun_Latn",
    "Armenian": "hye_Armn",
    "Indonesian": "ind_Latn",
    "Icelandic": "isl_Latn",
    "Italian": "ita_Latn",
    "Japanese": "jpn_Jpan",
    "Javanese": "jav_Latn",
    "Georgian": "kat_Geor",
    "Kazakh": "kaz_Cyrl",
    "Khmer": "khm_Khmr",
    "Kannada": "kan_Knda",
    "Korean": "kor_Hang",
    "Northern Kurdish": "kmr_Latn",
    "Lao": "lao_Laoo",
    "Lithuanian": "lit_Latn",
    "Luxembourgish": "ltz_Latn",
    "Plateau Malagasy": "plt_Latn",
    "Macedonian": "mkd_Cyrl",
    "Malayalam": "mal_Mlym",
    "Marathi": "mar_Deva",
    "Standard Malay": "zsm_Latn",
    "Dutch": "nld_Latn",
    "Norwegian Nynorsk": "nno_Latn",
    "Norwegian Bokmål": "nob_Latn",
    "Nepali": "npi_Deva",
    "Occitan": "oci_Latn",
    "Odia": "ory_Orya",
    "Eastern Panjabi": "pan_Guru",
    "Polish": "pol_Latn",
    "Portuguese": "por_Latn",
    "Dari": "prs_Arab",
    "Southern Pashto": "pbt_Arab",
    "Ayacucho Quechua": "quy_Latn",
    "Romanian": "ron_Latn",
    "Russian": "rus_Cyrl",
    "Sinhala": "sin_Sinh",
    "Slovak": "slk_Latn",
    "Slovenian": "slv_Latn",
    "Swedish": "swe_Latn",
    "Swahili": "swh_Latn",
    "Tamil": "tam_Taml",
    "Telugu": "tel_Telu",
    "Thai": "tha_Thai",
    "Tagalog": "tgl_Latn",
    "Turkish": "tur_Latn",
    "Uyghur": "uig_Arab",
    "Ukrainian": "ukr_Cyrl",
    "Urdu": "urd_Arab",
    "Vietnamese": "vie_Latn",
    "Chinese (Simplified)": "zho_Hans",
    "Chinese (Traditional)": "zho_Hant",
    "Zulu": "zul_Latn"
  };

  const inputlanguages: { [key: string]: string } = {
    "Chinese (Simplified)": "ch",
    "English": "en",
    "Korean": "korean",
    "Japanese": "japan",
    "Chinese (Traditional)": "chinese_cht",
    "Tamil": "ta",
    "Telugu": "te",
    "Kannada": "ka",
    "Latin script": "latin",
    "Arabic": "arabic",
    "Cyrillic script": "cyrillic",
    "Hindi": "devanagari"
  }




  const renderLandingSection = () => {
    const placeholderText = `Please write your thoughts to get the translation.`;

    return (
      <Box display="flex" flex={1} flexDirection="column" alignItems="center" padding={2}>
        <img style={{ width: '30px', height: '30px', objectFit: 'contain', marginBottom: 10, marginTop: 50, }} src={Translation} alt="Translation" />
        <Typography variant="h6" align="center" gutterBottom pb={5}>
          Translation
        </Typography>
        <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '50%', ml: 5.5 }}>
          {/* <Box sx={{ display: "flex", flexDirection: 'row', mb: 5, ml: 5.5, width: '90%' }}> */}
          {/* <FormControl variant="filled" sx={{ flex: 1, mx: 1 }}>
            <InputLabel id="demo-simple-select-filled-label">Input Language</InputLabel>
            <Select
              sx={{
                width: '85%',
                boxShadow: 10,
                borderRadius: 2,
                backgroundColor: '#f7f7f7',
              }}
              labelId="input-language-select-label"
              id="input-language-select"
              value={InputLanguage}
              label="Input Language"
              onChange={handleIutputLang}
            >
              {Object.entries(inputlanguages).map(([language, code]) => (
                <MenuItem key={code} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <FormControl variant="filled" sx={{ flex: 1, mx: 1, mb: 2 }}>
            <InputLabel id="output-language-select-label">Output Language</InputLabel>
            <Select
              sx={{
                width: '85%',
                boxShadow: 10,
                borderRadius: 2,
                backgroundColor: '#f7f7f7',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
              labelId="output-language-select-label"
              id="output-language-select"
              value={OutputLanguage}
              label="Output Language"
              onChange={handleOutputLang}
            >
              {Object.entries(languages).map(([language, code]) => (
                <MenuItem key={code} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box ml={-1}>
          {audioURL && (
            <audio controls>
              <source src={audioURL} type="audio/wav" />
            </audio>
          )}

        </Box>

        <TextField
          sx={{
            width: "85%",
            border: 'none',
            boxShadow: 10,
            borderRadius: 2,
            backgroundColor: '#f7f7f7',
            margin: 5,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&:hover fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
          placeholder={placeholderText}
          multiline
          rows={4}
          variant="outlined"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Box>
    );
  }


  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      {/* Header */}

      <Grid container bgcolor={'#F7F7F7'} sx={{ flex: 1, padding: 2, marginTop: '80px', }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12} borderRadius={'8px'} bgcolor={'#F7F7F7'}>
            <>
              <ToastContainer position="bottom-right" autoClose={2000} />

              <Grid container height={'78.1vh'}>
                {/* Left Grid */}
                {isSideVisible ? (
                  <Grid
                    item
                    md={2.8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius={"8px"}
                    bgcolor={'#F7F7F7'}
                    mr={1}

                    flexDirection={"column"}
                  >
                    <Box sx={{ width: "90%", mb: -1, mt: -2 }}>
                      <Box
                        sx={{
                          // borderStyle: "dashed",
                          borderWidth: 2,
                          width: "100%",
                          borderColor: "#ccc",
                          background: '#FEA349',
                          paddingTop: 2,
                          paddingBottom: 2,
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "220px",
                          top: "238px",
                          left: "50px",
                          gap: "0px",
                          borderRadius: "10px",
                          backgroundImage: `url(${Mic})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          backgroundSize: 'contain',
                        }}
                      >
                        {/* <IconButton sx={{ fontSize: 30 }} onClick={() => handleMicButton()}>
                  <MicIcon sx={{ fontSize: 30 }} />
                </IconButton> */}
                        <Typography variant="h6" color={"white"}>Record an audio</Typography>


                        <Button
                          startIcon={<MicIcon />}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleMicButton()}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: "90%",
                            boxShadow: 10,
                            gap: "0px",
                            opacity: "0px",
                            borderRadius: 1.5,
                            textTransform: "capitalize",
                            fontWeight: 600,
                            top: 40,
                            backgroundColor: "white !important", // Ensure this style is applied
                            color: "black !important",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "lightgray !important", // Optional: change color on hover
                            }
                          }}
                          disabled={audioFile.length > 0 || query !== ""}
                        >
                          {isRecording ? 'Stop Recording' : 'Start Recording'}
                        </Button>

                      </Box>
                    </Box>
                    <Typography
                      display={'flex'}
                      width={'41px'}
                      height={"41px"}
                      top={"614px"}
                      bgcolor={'black'}
                      color={"white"}
                      alignItems={'center'}
                      justifyContent={'center'}
                      textAlign={'center'}
                      borderRadius={"20px"}
                      alignSelf={"center"}
                      zIndex={99}
                      variant="body1">OR</Typography>
                    <Box sx={{ width: "90%", mt: 1 }}>
                      <FileUploader
                        className="uploader"
                        fileOrFiles={files}
                        onTypeError={onTypeError}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        onSizeError={onSizeError}
                        onDrop={onDrop}
                        onSelect={onSelect}
                        label="Upload file here"
                        dropMessageStyle={{ backgroundColor: "orange" }}
                        multiple={true}
                        disabled={query !== '' || audioURL && true}
                      >
                        <Box
                          sx={{
                            // borderStyle: "dashed",
                            borderWidth: 2,
                            width: "100%",
                            borderColor: "#ccc",
                            borderRadius: 3,
                            // backgroundColor: NETSMARTZ_THEME_COLOR,
                            background: '#FEA349', // Example gradient with #EE851E
                            paddingTop: 2,
                            paddingBottom: 2,
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // gap: 2,
                            height: "220px",
                            top: "238px",
                            // left: "50px",
                            gap: "0px",
                            opacity: "0px",
                            marginTop: -2,
                            backgroundImage: `url(${Upload})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                          }}
                        >
                          <CloudUploadIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "white",
                            }}
                          />
                          <Typography align="center" variant="h6" m={1} color={"white"}>Upload an audio/video or doc file</Typography>
                        </Box>
                      </FileUploader>
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => query ? handleUpload(query) : handleAudioUpload()}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: "100%",
                            boxShadow: 10,
                            borderRadius: 1.5,
                            textTransform: "capitalize",
                            // backgroundColor: "black",
                            fontWeight: 600,
                            backgroundColor: "#3d3d3d", // Ensure this style is applied
                            color: "white",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "black !important", // Optional: change color on hover
                            }
                          }}
                          disabled={audioFile.length === 0 && query === "" && audioURL === null || OutputLanguageCode === ""}
                        >
                          Translate Now
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                ) : null}


                {/* Right Grid */}
                <Grid
                  item
                  md={isSideVisible ? 9 : 12}
                  display="flex"
                  className="grid-right-pane"
                  sx={{
                    backgroundColor: "white",
                    borderColor: "lightgrey",
                    // paddingTop: "0.5rem",
                    borderRadius: '8px'
                  }}
                  justifyContent="center"
                // alignItems="center"
                >
                  {isChatEnabled ? (renderIsChatEnabled()) : (
                    <>
                      {audioFile.length !== 0 ? (<SelectedFiles files={audioFile} handleDeletedFiles={handleDeletedFiles} />) : (renderLandingSection())}
                    </>
                  )}
                </Grid>
              </Grid>

              {/* <JapaneseToEnglishDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                listening={listening}
                onStopListening={handleStopListening}
                transcript={transcript}
              /> */}

              {loading ? (
                <div className="spinner-container">
                  <InfinitySpin width="200" color="#F86F03" />
                </div>
              ) : null}
            </>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        ImageOption={ImageOption}
        onChange={handleImageOptionChange}
        inputlanguages={inputlanguages}
        onSubmitClick={handleSubmitClick}
        InputLanguage={InputLanguage}
        handleInputLang={handleInputLang}
      />
    </Box>
  );
}

export default MultiTranslation