import React, { useEffect } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { servicesCardConstant } from "../../utils/constant";
import CardList from "../card_list/CardList";

import "./Services.scss";
import { useSelector } from 'react-redux';
import { tagsApi } from '../../api_config/api_services';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { InfinitySpin } from 'react-loader-spinner';

function Services(): any {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const notifyError = (message: string) => toast.error(message);
  const [loading, setLoading] = React.useState(false)
  const [tags, setTags] = React.useState<any[]>([])
  const username = useSelector((state: any) => state?.auth?.username)

  // Handling card navigation
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
  };



  useEffect(() => {
    getTags(username)
  }, [username])

  const getTags = async (username: any) => {
    setLoading(true);
    await tagsApi(username)
      .then((result: any) => {
        if (result?.success) {
          // Handle successful response
          console.log(result?.data, 'result');
          setTags(result?.data?.tags)
          setLoading(false);
        } else {
          const message: string = result.data?.response?.data?.error || "Something Went Wrong.";
          notifyError(message);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        const error = err as AxiosError;
        // Consolidated error handling
        let errorMessage = "Something went wrong.";
        if (error.response) {
          const responseData = error.response.data as { error?: string };
          if (responseData?.error) {
            errorMessage = responseData.error;
          }
        } else {
          errorMessage = "Error occurred while setting up the request: " + error.message;
        }
        // Notify error and set response message
        notifyError(errorMessage);
        setLoading(false);
      });

  }

  const email = username

  // Check if username or email includes specific names
  const specificNames = ["shyam", "hilde", "gv", "gudbrand"];
  const containsSpecificName = specificNames.some(name =>
    email.includes(name)
  );

  // // Filter the cards
  // const filteredCards = servicesCardConstant.filter(data => {
  //   if (containsSpecificName) {
  //     return data.title === "SmartDigest: AI-Powered Document Summarization";
  //   }
  //   return true; // Adjust this to return other cards if necessary
  // });

  // const tags = useSelector((state: any) => state?.auth?.tags)

  // const filterTags = ["t2", "t3", "t4", "t5", "t6", "t7", "t8", "t9", "t10", "t11"];

  // Filter the cards
  const filteredCards = servicesCardConstant?.filter(data => tags?.includes(data?.tag));

  console.log(filteredCards);
  return (
    <div id="services" className={isMatch ? "sm-container" : "lg-container"}>
      {loading ? (
        <div className="spinner-container">
          <InfinitySpin width="200" color="#F86F03" />
        </div>
      ) : null}
      <Typography
        color="black"
        variant="h5"
        fontWeight={"bolder"}
        lineHeight={1}
        textAlign='center'
        padding='25px'
      >
        Explore Our Innovative Tools
      </Typography>
      <Grid container spacing={3} paddingBottom='10px !important' paddingLeft='40px !important' paddingRight='40px !important'>
        {filteredCards.map((data, index) => (
          <Grid
            style={{ display: "flex" }}
            justifyContent="center"
            item
            xs={12}
            key={index}
            sm={6}
            md={4}
          >
            <CardList
              key={index}
              image={data.image}
              title={data.title}
              descriptions={data.description}
              poweredBy={data.poweredBy}
              onCardClick={() => handleNavigate(data.path)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Services;
