import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { AxiosError } from "axios";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { InfinitySpin } from "react-loader-spinner";
import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import SpeechRecognitionDialog from "./SpeechRecognitionDialog";
import MicIcon from "@mui/icons-material/Mic";
import { speechToTextApi } from "../../api_config/api_services";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import HorizontalList from "../horizontalList/HorizontalList";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";


function SpeechToText() {
  const [files, setFile] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<any>(null);

  const [isSideVisible, setSideVisible] = useState(true);
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertColor | undefined;
    message: string | undefined;
    isVisible: boolean;
  }>();

  const handleUpload = async (query: any) => {
    if (query === '') {
      notifyError("Please record an audio");
    }
    else {
      setLoading(true)
      const Data: any = {
        text: query
      };
      const req = JSON.stringify(Data)
      await speechToTextApi(req).then((result: any) => {
        const SpeechToTextAPIData = result?.data;
        if (result?.success) {
          setResponseMessage({
            type: "success",
            message: "Success",
            isVisible: true,
          });
          setMessage(SpeechToTextAPIData);
          resetTranscript();
          setChatEnabled(true);
          setLoading(false);
        }
        else {
          const message: string = result?.data?.response?.data?.error || "Something Went Wrong."
          notifyError(message);
          setOpen(false);
          setLoading(false);
        }

      }).catch((err: any) => {
        const error = err as AxiosError;
        resetTranscript();

        // Consolidated error handling
        let errorMessage = "Something went wrong.";
        if (error.response) {
          const responseData = error.response.data as { error?: string };
          if (responseData?.error) {
            errorMessage = responseData.error;
          }
        } else {
          errorMessage = "Error occurred while setting up the request: " + error.message;
        }

        // Notify error and set response message
        notifyError(errorMessage);
        setResponseMessage({
          type: "error",
          message: errorMessage,
          isVisible: true,
        });
        setOpen(false);
        setLoading(false);
        notifyError(error.message || "something went wrong");
      })
    }
  };

  const notifyError = (message: string) => toast.error(message);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();

  const handleMicButton = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    handleStartListening();
  };

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    setIsDialogOpen(true);
    setIsListening(true);
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setQuery(transcript);
    resetTranscript();
    setIsListening(false);
    setIsDialogOpen(false);
    handleUpload(transcript)
  };


  const handleCloseDialog = () => {
    setIsDialogOpen(true);
  };


  const items = [
    { id: 1, text: "Meeting Transcription and Summarization" },
    { id: 2, text: "Customer Service Call Analysis" },
    { id: 3, text: "Market Research Interview Analysis" },
    { id: 4, text: "Lecture and Seminar Summarization" },
    { id: 5, text: "Legal Transcription and Briefing" },
    { id: 6, text: "Conference and Event Summaries" },
    { id: 7, text: "Medical Consultation Notes" },
    { id: 8, text: "Podcast and Webinar Recap" },
    { id: 9, text: "Training Session Recap" },
    { id: 10, text: "Press Conference Summaries" },
  ]

  const CaseStudies = [
    {
        id: 1,
        title: "Enhancing Customer Satisfaction",
        description: "Our speech-to-text summarizer tool helped a retail giant analyze customer feedback from call center recordings. By summarizing conversations into actionable insights, they swiftly addressed customer grievances, leading to a noticeable increase in satisfaction levels."
            },
    {
        id: 2,
        title: "Enhancing Business Communication",
        description: "A multinational corporation employed our tool to summarize weekly conference calls. By providing executives with succinct summaries, they improved communication efficiency, ensuring that key decisions and action items were effectively conveyed and implemented."
            },
    {
        id: 3,
        title: "Transforming Learning Experiences",
        description: "Educational institutions leveraged our tool to summarize lectures and seminars. This empowered students to review complex topics quickly and efficiently, leading to improved comprehension and retention of course materials."
    },
  ]


  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      {/* Header */}

      <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12} borderRadius={'8px'}>
            <>
              <ToastContainer position="bottom-right" autoClose={2000} />

              <Grid container height={'78.1vh'}>
                {/* Left Grid */}
                {isSideVisible ? (
                  <Grid
                    md={2.8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // className="grid-left-pane"
                    // bgcolor={NETSMARTZ_THEME_COLOR}
                    sx={{
                      background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                      borderRadius: '8px',
                  }}
                    borderRadius={"8px"}
                    mr={1}
                  >
                    <Box sx={{ width: "80%" }}>
                      <Box
                        sx={{
                          borderStyle: "dashed",
                          borderWidth: 2,
                          width: "100%",
                          borderColor: "#ccc",
                          borderRadius: 3,
                          backgroundColor: "#fff",
                          paddingTop: 5,
                          paddingBottom: 5,
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <IconButton sx={{ fontSize: 30 }} onClick={() => handleMicButton()}>
                          <MicIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                        <Typography variant="h6">Record an Audio</Typography>




                      </Box>
                      <Button
                        startIcon={<MicIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={() => handleMicButton()}
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          marginTop: "20px",
                          width: "100%",
                          boxShadow: 0,
                          // color: "white",
                          borderRadius: 1.5,
                          textTransform: "capitalize",
                          fontWeight: 600,
                          backgroundColor: "white !important", // Ensure this style is applied
                          color: "black !important",           // Ensure this style is applied
                          '&:hover': {
                            backgroundColor: "lightgray !important", // Optional: change color on hover
                          }
                        }}
                      >
                        Start Recording
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
                {/* Right Grid */}
                <Grid
                  item
                  md={isSideVisible ? 9 : 12}
                  display="flex"
                  className="grid-right-pane"
                  sx={{
                    backgroundColor: "white",
                    borderColor: "lightgrey",
                    borderRadius: '8px',
                    padding: "20px",
                  }}
                  justifyContent="center"
                // alignItems="center"
                >
                  {isChatEnabled ? (
                    <Box
                      display="flex" flexDirection="column" width="80%"
                    >
                      {/* Display Conversation Messages */}
                      <div className="pdf-container">
                        <Card
                          variant="outlined"
                          sx={{ marginBottom: "10px" }}
                        >
                          <CardContent>
                            <Typography textAlign={"center"} marginBottom={3} variant="h4">{"Summary"}</Typography>
                            <Typography variant="body1">{message?.summary}</Typography>
                          </CardContent>
                        </Card>

                      </div>
                    </Box>
                  ) : (
                    <>
                      {files.length !== 0 ? (
                        <Box display="flex" flexDirection="column" width="80%">
                          <Typography variant="h3" gutterBottom>
                            Selected Files
                          </Typography>
                        </Box>
                      ) : (
                        <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                          <Typography  textAlign= 'center' variant="h3">Please <span className="text-decorat">record an audio</span> to get the summary.</Typography>
                          <Typography className="mt-2" variant="h6">Note: Record atleast 30 words to generate summary.</Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <SpeechRecognitionDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                listening={listening}
                onStopListening={handleStopListening}
                transcript={transcript}
              />
              {loading ? (
                <div className="spinner-container">
                  <InfinitySpin width="200" color="#F86F03" />
                </div>
              ) : null}
            </>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
    </Box>
  );
}

export default SpeechToText;