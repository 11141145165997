import React, { useState } from 'react';
import Home from './Home';
import Sidebar from './Sidebar';
import './Chatbot.scss';
import Footer from '../footer/Footer';

const Chatbot: React.FC = () => {

  const [isSideVisible, setSideVisible] = useState(true);
  const [conversation, setConversation] = useState<any>([]);


  const handleNewChat = () => {
    setConversation([])
  }

  return (
    <>
      <div className='chatbot' >
        <Sidebar
          isSideVisible={isSideVisible}
          setSideVisible={setSideVisible}
          handleNewChat={handleNewChat}
        />
        <Home
          isSideVisible={isSideVisible}
          setSideVisible={setSideVisible}
          conversation={conversation}
          setConversation={setConversation}
        />
      </div>
      <Footer />
    </>
  );
};

export default Chatbot;
