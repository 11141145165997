import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Grid,
  Paper,
  Box,
  CardContent,
  Card,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SendIcon from "@mui/icons-material/Send";
import { ThreeDots } from "react-loader-spinner";
import BotGIF from "../../assets/images/bot.gif";
import chatBot from "../../assets/images/chatbot.png";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { ToastContainer, toast } from "react-toastify";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import { chatBotApi } from "../../api_config/api_services";
import { AxiosError } from "axios";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";

const QaDatabase = () => {
  const [query, setQuery] = useState<any>("");
  const [conversation, setConversation] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex]: any = useState(null);

  const [isListening, setIsListening] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  const notifyError = (message: string) => toast.error(message);

  const handleMicButton = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    handleStartListening();
  };

  useEffect(() => {
    if (listening && transcript) {
      setQuery(transcript);
    }
  }, [listening, transcript]);

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    setIsDialogOpen(true);
    setIsListening(true);
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
    setIsDialogOpen(false);
    resetTranscript();
  };

  const handleApiCall = async (query: any) => {
    setLoading(true);
    const requestBody = { query };
    const updatedConversation = [
      ...conversation,
      {
        user: query,
        bot: <ThreeDots height={20} width={40} color={NETSMARTZ_THEME_COLOR} />,
      },
    ];
    setConversation(updatedConversation);
    await chatBotApi(requestBody).then((result: any) => {
      if (result?.success) {
        const responseMessage = result?.data?.answer || "No response received.";
        const updatedConversationWithResponse = [
          ...updatedConversation.slice(0, -1),
          { user: query, bot: responseMessage },
        ];

        setConversation(updatedConversationWithResponse);
        setLoading(false);
        setQuery("");
      }
      else {
        const errorConversation = [
          ...conversation,
          { user: query, bot: "Error fetching data. Please try again." },
        ];
        setConversation(errorConversation);
        setLoading(false);
        setQuery("")
        console.log({ result })
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);

      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      const errorConversation = [
        ...conversation,
        { user: query, bot: "Error fetching data. Please try again." },
      ];
      setConversation(errorConversation);
      setLoading(false);
      setQuery("");
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
    })
  }

  const handleQuery = async () => {
    handleStopListening();
    if (query !== '') {
      handleApiCall(query)
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(true);
  };


  const [isStart, setIsStart] = useState(false);
  const [utterance, setUtterance]: any = useState(null);




  const handleStop = () => {
    setActiveIndex(null);
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsStart(false);
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    return () => {
      synth.cancel();
      handleStop()
    };
  }, []);

  const handleSpeak = (text: any, index: number) => {
    handleStop()
    setActiveIndex(index);
    setIsStart(true)
    const u: any = new SpeechSynthesisUtterance(text);
    setUtterance(u);
    const synth = window.speechSynthesis;
    synth.speak(u);

  }

  const items = [
    { id: 1, text: 'Customer Support Assistance' },
    { id: 2, text: 'E-commerce and Sales Assistance' },
    { id: 3, text: 'E-commerce Product Recommendations' },
    { id: 4, text: 'HR and Recruitment Assistance' },
    { id: 5, text: 'IT Helpdesk and Technical Support' },
    { id: 6, text: 'Insurance Claims and Policy Information' },
    { id: 7, text: 'Financial Advice and Banking Services' },
    { id: 8, text: 'Healthcare Consultation and Triage' },
    { id: 9, text: 'Mental Health Support and Counselling' },
    { id: 10, text: 'Medical Symptom Checker' },
    { id: 11, text: 'Fitness and Wellness Coaching' },
    { id: 12, text: 'Travel Planning and Recommendations' },
    { id: 13, text: 'Educational Tutoring' },
    { id: 14, text: 'Legal Advice and Document Preparation' },
  ]

  const CaseStudies = [
    {
      id: 1,
      title: "Customer Support Automation for E-commerce",
      description: "Our AI chatbot revolutionized customer support for an e-commerce giant. By integrating the chatbot into their website, we enabled 24/7 assistance, resolving queries about orders, returns, and product information instantly. This led to a 40% reduction in support ticket volume and a 30% increase in customer satisfaction."
    },
    {
      id: 2,
      title: "Personalized Learning Assistant for a Driving School Software ",
      description: "We partnered with a global leader oin the driving school software domain to enhance their student experience using our AI chatbot. The bot provided 24/7 support, answering queries about road signs, traffic laws, and lesson scheduling. It also offered interactive quizzes to reinforce learning. This resulted in improved student engagement and a 20% increase in course completion rates."
    },
    {
      id: 3,
      title: "Streamlined HR Support for a Global Corporation",
      description: "A global corporation implemented our AI chatbot to streamline their HR support. The chatbot handled common inquiries about company policies, benefits, and leave applications, significantly reducing the HR department's workload. This improved efficiency allowed the HR team to focus on more strategic tasks, improving overall employee satisfaction."
    }
  ]

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      //setValue(event.target.value);
      // Execute your desired function here
      if (event.target.value !== '') {
        handleApiCall(event.target.value)
        console.log('You entered:', event.target.value);
      }
      else {
        console.log('You entered nothing:');
      }

    }
  }
  console.log(conversation)
  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      <ToastContainer position="bottom-right" autoClose={2000} />

      <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12}>
            <Paper
              sx={{
                padding: "20px",
                borderRadius: "8px",
                minHeight: "78.1vh",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Display Conversation */}
              <Box
                sx={{
                  maxHeight: "calc(70vh - 120px)",
                  overflowY: "auto",
                  marginBottom: "20px",
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar
                  },
                }}
              >
                {/* Display Welcome Message */}
                {!conversation.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "50vh",
                      fontStyle: "italic",
                    }}
                  >
                    <Typography variant="body1" fontSize={'18px'} fontWeight={'600'} textAlign="center">
                      Welcome to ConverseAI: Your Virtual Companion
                      <Typography variant="body2"> How can I assist you today?</Typography>
                    </Typography>
                    <img
                      className="item_text"
                      src={chatBot}
                      alt="GIF"
                      style={{
                        width: "125px",
                        height: "125px",
                        marginTop: "20px",
                      }}
                    />
                  </Box>
                )}

                {/* Display Conversation Messages */}
                {conversation.map((item: any, index: any) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{ marginBottom: "10px", border: 'none' }}
                  >
                    <CardContent>
                      {item?.user && (
                        <Typography
                          variant="body1"
                          sx={{ width: 'fit-content', marginBottom: "4px", fontWeight: "bold", padding: '7px', paddingLeft: '15px', paddingRight: '15px', backgroundColor: 'rgb(237 237 237)', borderRadius: '10px' }}
                        >
                          Question: {item?.user}
                        </Typography>
                      )}
                      <Typography sx={{
                        width: '85%', float: 'right', padding: '15px', border: '1px solid rgb(237 237 237)', borderRadius: '10px'

                      }} variant="body1">
                        {!loading && (isStart ? <VolumeUpIcon onClick={() => handleStop()} sx={{ color: activeIndex === index ? '#F58220' : 'black', float: 'right' }} /> : <VolumeUpIcon onClick={() => handleSpeak(item.bot, index)} sx={{ color: activeIndex === index ? '#F58220' : 'black', float: 'right' }} />)}
                        <p>{item.bot}</p>

                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>

              {/* Input Textfield and Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "30px",
                  width: "97%",
                  borderTop: "1px solid #d3d3d3",
                  pt: 3,
                }}
              >
                <Box
                  width={"95%"}
                  display={'flex'}
                  flexDirection={'row'}
                  sx={{
                    backgroundColor: "white",
                  }}
                >
                  <TextField
                    placeholder="Enter your query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#B2BAC2",
                        },
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          border: "0.1px solid #B2BAC2",
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {loading ? (
                            <IconButton>
                              <ThreeDots height={30} width={30} color={NETSMARTZ_THEME_COLOR} />
                            </IconButton>
                          ) : (
                            <>
                              <IconButton onClick={handleQuery}>
                                <SendIcon />
                              </IconButton>
                            </>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box bgcolor={NETSMARTZ_THEME_COLOR} ml={1} borderRadius={'5px'}>
                    {!listening ? (
                      <IconButton onClick={handleMicButton}>
                        <MicIcon sx={{ color: 'white' }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleStopListening}>
                        <StopCircleIcon sx={{ color: 'white' }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          {/* </Grid> */}
        </Grid>


        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
    </Box>

  );
};

export default QaDatabase;
