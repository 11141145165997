import {
    AlertColor,
    Box,
    Button,
    CardContent,
    Card,
    Grid,
    Typography,
    FormControl,
    TextField,
    FormHelperText,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import TxtImage from '../../assets/images/txt-file.png';
import { codeEvaluationSummaryApi, codeEvaluationUserApi, mcqGenerateApi, technicalInterviewApi } from "../../api_config/api_services";
import Form from "../technical_interview/Form";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { setCodeEvaluationData } from "../../api_config/slices/codeEvaluationSlice";
import InstructionsModal from "./InstructionModal";
import SelectedFiles from "../common/SelectedFiles";
import BackButton from "../common/BackButton";
import useFileUpload from "../../utils/hooks/useFileUpload";
import Footer from "../footer/Footer";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";

const fileTypes: string[] = ["TXT"];

function CodeEvaluationTool() {
    const [time, setTime] = useState("");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<any>(null);
    const [summary, setSummary] = useState<any>(null);
    const [isSideVisible, setSideVisible] = useState(false);
    const [isFormVisible, setisFormVisible] = useState(true);
    const [responseMessage, setResponseMessage] = useState<{
        type: AlertColor | undefined;
        message: string | undefined;
        isVisible: boolean;
    }>();
    const [openInstructionModal, setOpenInstructionModal] = useState(true);
    const dispatch = useDispatch();
    const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

    // Use the useSelector hook to access the questionDict from the Redux store
    const CodeEvaluationData = useSelector((state: any) => state?.codeEvaluation)
    const Question = "generated Coding Question"
    const notifyError = (message: string) => toast.error(message);


    useEffect(() => {
        return () => {
            dispatch(setCodeEvaluationData({
                generatedCodingQuestion: '',
                employee_id: '',
                time_alloted: ''
            }));
        }
    }, [])

    const handleInstructionModal = () => {
        setOpenInstructionModal(!openInstructionModal)
    }

    const handleCallback = (result: any) => {
        handleUserData(result)
    }

    const handleUserData = async (result: any) => {
        setOpen(true);
        const Data: any = {
            emp_id: result?.employeeid,
            username: result?.username,
            Name: result?.name,
            Tech_stack: result?.techstack,
            Topics_of_interest: result?.topics,
            Total_time_minutes: result?.time,
            YOE: result?.experience
        };

        const req = JSON.stringify(Data)

        await codeEvaluationUserApi(req).then((result: any) => {
            const codeEvaluationUserAPIData = result;
            setResponseMessage({
                type: "success",
                message: "Success",
                isVisible: true,
            });
            dispatch(setCodeEvaluationData({
                generatedCodingQuestion: codeEvaluationUserAPIData && codeEvaluationUserAPIData[Question],
                employee_id: codeEvaluationUserAPIData?.employee_id,
                time_alloted: codeEvaluationUserAPIData?.time_alloted
            }));
            setMessage(codeEvaluationUserAPIData);
            setSideVisible(true)
            setisFormVisible(false)
            setOpen(false);

        }).catch((err: any) => {
            const error = err as AxiosError;

            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            setResponseMessage({
                type: "error",
                message: errorMessage,
                isVisible: true,
            });
            setOpen(false);
        })

    };

    const handleUpload = async () => {
        if (files.length === 0 || time === '') {
            notifyError("Please upload the file & enter the time taken");
            return;
        }
        // Check if any of the files are empty
        const emptyFile = files.find((file: any) => file.size === 0);
        if (emptyFile) {
            notifyError("The uploaded file has no content.");
            return;
        }

        setOpen(true);
        const formData = new FormData();
        files.forEach((file: any, index: number) => {
            formData.append(`file`, file);

        });

        formData.append("question_text", CodeEvaluationData?.codingQuestion);
        formData.append("time_taken", time);
        formData.append("time_allotted", CodeEvaluationData?.timeAllotted);
        formData.append("employee_id", CodeEvaluationData?.employeeId);


        await codeEvaluationSummaryApi(formData).then((result: any) => {
            const codeEvaluationSummaryData = result?.data;
            if (result?.success) {
                setFiles([])
                setResponseMessage({
                    type: "success",
                    message: "Success",
                    isVisible: true,
                });
                setSummary(codeEvaluationSummaryData);
                setTime('')
                setChatEnabled(true)
                setSideVisible(false)
                setOpen(false);
            }
            else {
                const message: string = result.data?.response?.data?.error || "Something Went Wrong."
                notifyError(message);
                setOpen(false);

            }

        }).catch((err: any) => {
            const error = err as AxiosError;

            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            setResponseMessage({
                type: "error",
                message: errorMessage,
                isVisible: true,
            });
            setOpen(false);
        })

    };

    const handleBack = () => {
        setChatEnabled(false)
        setSideVisible(true);
        setFiles([])
        setSummary(null)
    }


    const handleBackToQuesGen = () => {
        setChatEnabled(false)
        setSideVisible(false);
        setFiles([])
        setSummary(null)
        setisFormVisible(true)
    }

    const items = [
        { id: 1, text: "Employee Recruitment and Screening" },
        { id: 2, text: "Internal Skill Gap Analysis" },
        { id: 3, text: "Onboarding Processes for New Hires" },
        { id: 4, text: "Freelancer and Contractor Evaluation" },
        { id: 5, text: "Academic Admissions and Placement Tests" },
        { id: 6, text: "Certification and Credentialing" },
        { id: 7, text: "Workforce Upskilling and Reskilling Initiatives" },
        { id: 8, text: "Hackathons and Coding Competitions" },
        { id: 9, text: "Performance Review and Appraisal Systems" },
        { id: 10, text: "Software and IT Service Vendor Evaluation" },
        { id: 11, text: "Internship and Apprenticeship Programs" },
        { id: 12, text: "Research and Development Skill Assessments" },
        { id: 13, text: "Online Learning Platforms" },
        { id: 14, text: "Remote Work Assessment" },
        { id: 15, text: "Project Bidding Platforms" },
        { id: 16, text: "Customized Learning Pathways for Developers" },
    ]

    const CaseStudies = [
        {
          id: 1,
          title: "Streamlining Recruitment Processes",
          description: "We assisted a tech recruiting firm in streamlining their candidate evaluation process using our AI coding skills tool. By automating the initial screening of technical skills, the firm reduced time-to-hire and identified top talent more efficiently, resulting in higher-quality placements for their clients."
        },
        {
          id: 2,
          title: "Enhancing Learning and Development Programs",
          description: "Our solution empowered a large enterprise to tailor their employee training programs effectively. By analyzing coding skills across their workforce, the company identified skill gaps and provided targeted training, leading to improved job performance and career growth opportunities for employees."
        },
        {
          id: 3,
          title: "Driving Innovation in Education",
          description: "We collaborated with a university to innovate their computer science curriculum. By integrating our AI coding skills tool into coursework, the university offered students personalized feedback on coding assignments, fostering a more interactive and adaptive learning environment."
        },
    ]


    return (
        <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
            {/* Header */}

            <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
                <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
                    {/* Horizontal List */}
                    <HorizontalList items={items} />

                    {/* Chat Container */}
                    {/* <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
              > */}
                    <Grid item sm={8} lg={12} >
                        <>
                            <ToastContainer position="bottom-right" autoClose={2000} />

                            <Grid container height={'78.1vh'}>
                                {/* Left Grid */}
                                {isSideVisible ? (
                                    <Grid
                                        item
                                        md={2.8}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        // className="grid-left-pane"
                                        // bgcolor={NETSMARTZ_THEME_COLOR}
                                        sx={{
                                            background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                                        }}
                                        borderRadius={'8px'}
                                        mr={1}
                                    >
                                        <Box sx={{ width: "80%" }}>
                                            <FileUploader
                                                className="uploader"
                                                fileOrFiles={files}
                                                onTypeError={onTypeError}
                                                handleChange={handleChange}
                                                name="file"
                                                types={fileTypes}
                                                onSizeError={onSizeError}
                                                onDrop={onDrop}
                                                onSelect={onSelect}
                                                label="Upload file here"
                                                dropMessageStyle={{ backgroundColor: "orange" }}
                                                multiple={true}
                                            >
                                                <Box className="upload-box" >
                                                    <img
                                                        src={TxtImage}
                                                        alt="Icon"
                                                        style={{
                                                            width: "60px",
                                                            height: "60px",
                                                            color: NETSMARTZ_THEME_COLOR,
                                                        }}
                                                    />
                                                    <Typography variant="subtitle1">Upload file</Typography>
                                                </Box>
                                            </FileUploader>

                                            <FormControl fullWidth>
                                                <TextField
                                                    type="number"
                                                    placeholder="Time Taken"
                                                    value={time}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // Regular expression to match only numbers between 1 and 20
                                                        const isValidInput = /^\d{1,3}$/.test(value) && parseInt(value) >= 1 && parseInt(value);
                                                        if (isValidInput || value === '') {
                                                            setTime(value);
                                                        }
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        mt: 5,
                                                        bgcolor: 'white',
                                                        fontSize: '0.600rem',
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "#B2BAC2",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "black",
                                                            },
                                                            "&.Mui-focused fieldset": {
                                                                border: "0.1px solid #B2BAC2",
                                                            },
                                                        },
                                                    }}
                                                    inputProps={{
                                                        style: { fontSize: '14px' } // Adjust the font size here
                                                    }}
                                                />
                                                {time === '' && <FormHelperText sx={{ marginLeft: 0.5, color: 'white' }}>Note: Enter the Time taken to solve the Problem.</FormHelperText>}
                                            </FormControl>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleUpload}
                                                    className="upload-button"
                                                    sx={{
                                                        backgroundColor: "white !important", // Ensure this style is applied
                                                        color: "black !important",           // Ensure this style is applied
                                                        '&:hover': {
                                                            backgroundColor: "lightgray !important", // Optional: change color on hover
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Box>
                                    </Grid>
                                ) : null}
                                {/* Right Grid */}
                                <Grid
                                    item
                                    md={isSideVisible ? 9 : 12}
                                    // mt={-2}
                                    display="flex"
                                    className="grid-right-pane"
                                    sx={{
                                        backgroundColor: "white",
                                        borderColor: "lightgrey",
                                        borderRadius: "8px"
                                        // paddingTop: "2rem",
                                    }}
                                    justifyContent="center"
                                // alignItems="center"
                                >
                                    {isChatEnabled ? (
                                        <Box
                                            display="flex" flexDirection="column" width="90%"
                                        >
                                            {/* Display Conversation Messages */}
                                            <div className="pdf-container1">
                                                <BackButton handleBack={handleBack} title='Back To File Selection' style={{ marginRight: '10px' }} />
                                                <Typography textAlign={"center"} variant="h4"
                                                    sx={{ marginBottom: "4px", fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}> Results </Typography>
                                                {summary &&
                                                    <>
                                                        <Card
                                                            variant="outlined"
                                                            sx={{ marginBottom: "10px" }}
                                                        >
                                                            <CardContent>
                                                                {summary?.Result && (
                                                                    <>
                                                                        <Typography textAlign={"center"} variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}> Approach</Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold" }}
                                                                        >
                                                                            {summary?.Result.approach}
                                                                        </Typography>

                                                                    </>
                                                                )}
                                                            </CardContent>
                                                        </Card>

                                                        <Card
                                                            variant="outlined"
                                                            sx={{ marginBottom: "10px" }}
                                                        >
                                                            <CardContent>
                                                                {summary?.Result && (
                                                                    <>
                                                                        <Typography textAlign={"center"} variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}> Feedback </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold" }}
                                                                        >
                                                                            {summary?.Result.feedback}
                                                                        </Typography>

                                                                    </>
                                                                )}
                                                            </CardContent>
                                                        </Card>

                                                        <Card
                                                            variant="outlined"
                                                            sx={{ marginBottom: "10px" }}
                                                        >
                                                            <CardContent>
                                                                {summary?.Result && (
                                                                    <>
                                                                        <Typography textAlign={"center"} variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}> Logic </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold" }}
                                                                        >
                                                                            {summary?.Result.logic}
                                                                        </Typography>

                                                                    </>
                                                                )}
                                                            </CardContent>
                                                        </Card>


                                                        <Card
                                                            variant="outlined"
                                                            sx={{ marginBottom: "10px" }}
                                                        >
                                                            <CardContent>
                                                                {summary?.Result && (
                                                                    <>
                                                                        <Typography textAlign={"center"} variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}> Technical Parameter </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            sx={{ marginBottom: "4px", fontWeight: "bold" }}
                                                                        >
                                                                            {summary?.Result.technical_parameter}
                                                                        </Typography>

                                                                    </>
                                                                )}
                                                            </CardContent>
                                                        </Card>
                                                    </>
                                                }
                                            </div>
                                        </Box>
                                    ) : (
                                        <>
                                            {files.length !== 0 ? (
                                                <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                                            ) : (
                                                <Box display="flex" flexDirection={'column'} alignItems="center" paddingInline={2}>
                                                    {isFormVisible ?
                                                        <div className="pdf-container1" >
                                                            <Box sx={{ p: 2 }}>
                                                                <Form
                                                                    isCodeEval
                                                                    handleCallback={handleCallback}
                                                                    formTitle="Please Complete The Following Fields to Generate The Coding Problem Statement."
                                                                />
                                                            </Box>

                                                        </div>

                                                        :
                                                        <>
                                                            {message &&
                                                                <><BackButton handleBack={handleBackToQuesGen} title='Back To Question Generation' style={{ marginRight: '10px' }} /><Typography padding='5px' sx={{borderBottom: '1px solid #d8d5d1'}} variant="h4">{"Question:"}</Typography></>}
                                                            <Typography mt={2} variant="h6">{message && message[Question]}</Typography>

                                                        </>
                                                    }
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={3.25} >
                                {openInstructionModal && (
                                    <InstructionsModal openInstructionModal={openInstructionModal} handleInstructionModal={handleInstructionModal} />
                                )}
                            </Grid>

                            {open ? (
                                <div className="spinner-container">
                                    <InfinitySpin width="200" color="#F86F03" />
                                </div>
                            ) : null}
                        </>
                    </Grid>
                    {/* </Grid> */}
                </Grid>

                {/* Vertical List of Cards */}
                <VerticalCardList items={CaseStudies} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default CodeEvaluationTool