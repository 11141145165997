import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import MenuIcon from '@mui/icons-material/Menu';
import HistoryIcon from '@mui/icons-material/History';

const Sidebar: React.FC<any> = ({ isSideVisible, setSideVisible, handleNewChat }) => {

  return (
    <>
      {isSideVisible && (
        <Box
          mt={9}
          sx={{
            width: 400,
            bgcolor: '#fff',
            height: '93vh',
            p: 2,
            mr: 2,
            boxShadow: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
              <IconButton onClick={() => setSideVisible(false)}>
                <MenuIcon sx={{ color: '#7e7e7e' }} />
              </IconButton>

              <IconButton onClick={() => handleNewChat()}>
                <CreateIcon sx={{ color: '#7e7e7e' }} />
              </IconButton>
            </Box>
          </Box>


          <Divider />

          <Box sx={{
            flex: 1, overflowY: 'auto', "&::-webkit-scrollbar": {
              display: "none", // Hide the scrollbar
            },
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <HistoryIcon />
              <Typography
                variant="h6"
                sx={{ fontSize: 20, ml: 1 }}
              >
                History
              </Typography>
            </Box>
            <List>
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#7e7e7e', mb: 1 }}
                >
                  Today
                </Typography>
                <ListItemButton to="/react-guide" text="React TypeScript UI Guide" />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#7e7e7e', mb: 1 }}
                >
                  Yesterday
                </Typography>
                <ListItemButton to="/display-image-uri" text="Display Image Using URI" />
                <ListItemButton to="/lazy-pagination-react" text="Lazy Pagination in React" />
                <ListItemButton to="/fix-imageload-component" text="Fix ImageLoad Component" />
                <ListItemButton to="/react-native-efficient" text="React Native: Efficient Cross-Platform" />
                <ListItemButton to="/fix-jinja2templates-error" text="Fix Jinja2Templates Error" />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#7e7e7e', mb: 1 }}
                >
                  Previous 7 Days
                </Typography>
                <ListItemButton to="/record-send-audio" text="Record and Send Audio" />
                <ListItemButton to="/fix-fastapi-code" text="Fix FastAPI code" />
                <ListItemButton to="/handle-multiple-api-calls" text="Handle Multiple API Calls" />
                <ListItemButton to="/python-setup-project-structure" text="Python Setup & Project Structure" />
              </Box>
            </List>
          </Box>
        </Box>
      )}
    </>
  );
};

interface ListItemButtonProps {
  to: string;
  text: string;
}

const ListItemButton: React.FC<ListItemButtonProps> = ({ to, text }) => (
  <NavLink to={to} style={{ textDecoration: 'none' }}>
    {({ isActive }) => (
      <ListItem
        button
        sx={{
          p: 1,
          borderRadius: 1,
          backgroundColor: isActive ? '#e0e0e0' : 'transparent',
          fontWeight: isActive ? 'bold' : 'normal',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <ListItemText primary={text} sx={{ color: '#333' }} />
      </ListItem>
    )}
  </NavLink>
);

export default Sidebar;
