import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, RadioGroup, FormControlLabel, Radio, Button, Typography, Box } from '@mui/material';
import ParagraphIcon from '@mui/icons-material/Subject'; // Example icons
import BulletListIcon from '@mui/icons-material/FormatListBulleted';
import BothIcon from '@mui/icons-material/ViewList';

const ViewModeModal = ({ open, onClose, viewMode, onChange, onArticleClick }: any) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h5" component="div">
                    Select View Mode
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1" sx={{ mb: 2 }}>
                    Please select how you would like to view the summary.
                </DialogContentText>
                <RadioGroup value={viewMode} onChange={onChange}>
                    <FormControlLabel
                        value="paragraph"
                        control={<Radio />}
                        label={
                            <Box display="flex" alignItems="center">
                                <ParagraphIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">Paragraph</Typography>
                            </Box>
                        }
                    />
                    <FormControlLabel
                        value="bullet"
                        control={<Radio />}
                        label={
                            <Box display="flex" alignItems="center">
                                <BulletListIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">Bullet List</Typography>
                            </Box>
                        }
                    />
                    <FormControlLabel
                        value="both"
                        control={<Radio />}
                        label={
                            <Box display="flex" alignItems="center">
                                <BothIcon sx={{ mr: 1 }} />
                                <Typography variant="body1">Both</Typography>
                            </Box>
                        }
                    />
                </RadioGroup>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', px: 3, py: 2 }}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onArticleClick} color="primary" variant="contained">
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewModeModal;
