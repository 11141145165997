import { Typography } from "@mui/material";
import React from "react";
import "./Home.scss";
import Services from "../services/Services";
function Home() {
  return (
    <div>
      <div className="hero">
        <div className="content">
          <Typography
            color="white"
            variant="body1"
            sx={{ fontSize: '4.8rem !important' }}
            fontWeight={"bolder"}
            lineHeight={1}
          >
            Building the future of <b>AI</b> together
          </Typography>
        </div>
      </div>
      <Services />
    </div>
  );
}

export default Home;
