import React from 'react';
import DataComponent from './DataComponent';

const Data = () => {
    const data = {
        "message": {
            "!": [
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=318&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 58,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/a8dbca78-c36b-4ee0-9808-1b37719b4008.png",
                    "title": "Alt du vil vite om kjæledyret – på ett sted!"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=381&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 114,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/b41bdcc8-9a36-4539-a38f-c3dfb7facf3b.png",
                    "title": "Venn katten din til transportburet"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=474&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 201,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/467de7ba-2242-4915-a24b-0a01dbb55db0.Jpeg",
                    "title": "Tips til aktivisering av katten din"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=499&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 225,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/1b6c6a7b-dacb-4ecf-9640-43526d0abe0e.Jpeg",
                    "title": "Har hunden din forvillet seg bort? Fortvil ikke!"
                }
            ],
            "Adferd": [
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=304&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 45,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/2d55cd7b-ab44-4823-a4dc-c879fe8725f0.Jpeg",
                    "title": "Tips fra Maren om `Å være hjemme alene`"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=305&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 46,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/ed98888e-70a3-413c-b34c-0c228e6d1ffd.png",
                    "title": "Tips fra Maren om `Biting`"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=306&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 47,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/7d0a5b58-3acd-4e3d-8240-8fb8514f7a85.png",
                    "title": "Tips fra Maren om `Hvordan møte andre hunder`"
                },
                {
                    "article_link": "https://nyhetsbrev.dyreidentitet.no/TemplateArticle/Showtemplate?TemplateId=307&OGUID=8C899CC4-0EC7-430B-87DB-D3D61EE89111&PGUID=DB5E396E-40EB-4A00-BEB7-49DCD77A3FA1",
                    "id": 48,
                    "image_link": "https://nyhetsbrev.dyreidentitet.no/Content/UploadedImages/ContentTemplate/ArticleThumb/2f01f057-b6d2-4ca7-8eb9-547c68ec71a1.Jpeg",
                    "title": "Tips fra Maren om `Hvordan få valpen stueren`"
                }
            ]
        }
    };

    return (
        <div>
            <h1>Data Mapped by Category</h1>
            <DataComponent data={data} />
        </div>
    );
};

export default Data;
