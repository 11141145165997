import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
    AlertColor,
    Box,
    Button,
    CardContent,
    Card,
    Grid,
    Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import { mcqGenerateApi } from "../../api_config/api_services";
import SelectedFiles from "../common/SelectedFiles";
import useFileUpload from "../../utils/hooks/useFileUpload";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";

const fileTypes: string[] = ["TXT", "PDF", "DOCX"];

function McqGenerator() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<any>(null);
    const [isSideVisible, setSideVisible] = useState(true);
    const [responseMessage, setResponseMessage] = useState<{
        type: AlertColor | undefined;
        message: string | undefined;
        isVisible: boolean;
    }>();
    const notifyError = (message: string) => toast.error(message);
    const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

    const apiCallMcqGenerator = async (formData: any) => {

        await mcqGenerateApi(formData).then((result: any) => {
            console.log(result, "REsult")
            const mcqGeneratorAPIData = result?.data?.mcqs;
            if (result?.success) {
                setResponseMessage({
                    type: "success",
                    message: "Success",
                    isVisible: true,
                });
                setMessage(mcqGeneratorAPIData);
                setChatEnabled(true)
                setOpen(false);
                setFiles([])
            }
            else {

                const message: string = result?.data?.response?.data?.error || "Something Went Wrong."
                notifyError(message);
                setOpen(false);
            }

        }).catch((err: any) => {
            const error = err as AxiosError;

            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            setResponseMessage({
                type: "error",
                message: errorMessage,
                isVisible: true,
            });
            setOpen(false);
        })

    }
    const handleUpload = async () => {
        if (files.length === 0) {
            notifyError("No files uploaded.");
            return;
        }
        // Check if any of the files are empty
        const emptyFile = files.find((file: any) => file.size === 0);
        if (emptyFile) {
            notifyError("One or more uploaded files has no content.");
            return;
        }

        setOpen(true);
        const formData = new FormData();
        files.forEach((file: any, index: number) => {
            formData.append(`file`, file);

        });

        apiCallMcqGenerator(formData)
    };

    const items = [
        { id: 1, text: "Educational Platforms" },
        { id: 2, text: "Corporate Training Programs" },
        { id: 3, text: "Assessment and Evaluation Systems" },
        { id: 4, text: "Recruitment and Hiring Processes" },
        { id: 5, text: "Language Learning Applications" },
        { id: 6, text: "Medical Education and Assessment" },
        { id: 7, text: "Certification Examinations" },
        { id: 8, text: "Competitive Exam Preparation Platforms" },
        { id: 9, text: "Compliance Training Modules" },
        { id: 10, text: "Gamified Learning Environments" },
    ]

    const CaseStudies = [
        {
            id: 1,
            title: "Enhancing Learning Platforms",
            description: "A prominent online education platform integrated our MCQ generator to diversify their assessment methods. With tailor-made coding questions generated by our AI, they enriched the learning experience, providing students with challenging yet engaging exercises to reinforce their programming skills."
        },
        {
            id: 2,
            title: "Ensuring Compliance in Certification Exams",
            description: "A certification body in the IT industry adopted our MCQ generator to ensure the integrity and fairness of their exams. With randomly generated coding questions based on predefined criteria, they thwarted cheating attempts and upheld the credibility of their certification process, earning trust from both candidates and employers."
        },
        {
            id: 3,
            title: "Optimizing Recruitment Processes",
            description: "A recruitment agency incorporated our MCQ generator tool to streamline the screening process for technical roles.With automated creation and grading of coding assessments, they expedited candidate evaluation, allowing recruiters to focus more on qualitative aspects of hiring while ensuring candidates' technical competence."
        },
    ]


    return (
        <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
            {/* Header */}

            <Grid container bgcolor={'#F7F7F7'} sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
                <Grid item xs={12} lg={9} px={2} bgcolor={'#F7F7F7'}>
                    {/* Horizontal List */}
                    <HorizontalList items={items} />

                    {/* Chat Container */}
                    {/* <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
              > */}
                    <Grid item sm={8} lg={12} borderRadius={'8px'}>
                        <>
                            <ToastContainer position="bottom-right" autoClose={2000} />

                            <Grid container height={'78.1vh'}>
                                {/* Left Grid */}
                                {isSideVisible ? (
                                    <Grid
                                        item
                                        md={2.8}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        // className="grid-left-pane"
                                        // bgcolor={NETSMARTZ_THEME_COLOR}
                                        sx={{
                                            background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                                            borderRadius: '8px',
                                        }}
                                        borderRadius={"8px"}
                                        mr={1}
                                    >
                                        <Box sx={{ width: "80%" }}>
                                            <FileUploader
                                                className="uploader"
                                                fileOrFiles={files}
                                                onTypeError={onTypeError}
                                                handleChange={handleChange}
                                                name="file"
                                                types={fileTypes}
                                                onSizeError={onSizeError}
                                                onDrop={onDrop}
                                                onSelect={onSelect}
                                                label="Upload file here"
                                                dropMessageStyle={{ backgroundColor: "orange" }}
                                                multiple={true}
                                            >
                                                <Box
                                                    sx={{
                                                        borderStyle: "dashed",
                                                        borderWidth: 2,
                                                        width: "100%",
                                                        borderColor: "#ccc",
                                                        borderRadius: 3,
                                                        backgroundColor: "#fff",
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        flexDirection: "column",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <FileCopyIcon style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        color: NETSMARTZ_THEME_COLOR,
                                                    }}
                                                    />

                                                    {/* <img
                                        src={TxtImage}
                                        alt="Icon"
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            color: NETSMARTZ_THEME_COLOR,
                                        }}
                                    /> */}
                                                    <Typography variant="subtitle1">Upload files</Typography>
                                                </Box>
                                            </FileUploader>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleUpload}
                                                    sx={{
                                                        marginTop: "20px",
                                                        width: "100%",
                                                        backgroundColor: "#3d3d3d", // Ensure this style is applied
                                                        color: "white",           // Ensure this style is applied
                                                        '&:hover': {
                                                          backgroundColor: "black !important", // Optional: change color on hover
                                                        }
                                                    }}
                                                    // className="upload-button"
                                                    disabled={files.length === 0}
                                                >
                                                    Generate MCQ's
                                                    {/* Submit */}
                                                </Button>
                                            </div>
                                        </Box>
                                    </Grid>
                                ) : null}
                                {/* Right Grid */}
                                <Grid
                                    item
                                    md={isSideVisible ? 9 : 12}
                                    display="flex"
                                    className="grid-right-pane"
                                    sx={{
                                        backgroundColor: "white",
                                        borderColor: "lightgrey",
                                        borderRadius: '8px'
                                        // paddingTop: "2rem",
                                    }}
                                    justifyContent="center"
                                // alignItems="center"
                                >
                                    {isChatEnabled ? (
                                        <Box
                                            display="flex" flexDirection="column" width="80%"
                                        >
                                            {/* Display Conversation Messages */}
                                            <div className="pdf-container1">
                                                {message && message?.map((item: any, index: any) => (
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ marginBottom: "10px", border: 'none' }}
                                                    >
                                                        <CardContent>
                                                            {item.question && (
                                                                <Typography
                                                                    variant="body1"
                                                                    // sx={{ marginBottom: "4px", fontWeight: "bold" }}
                                                                    sx={{ marginBottom: "4px", fontWeight: "bold", padding: '5px', paddingLeft: '15px', backgroundColor: 'rgb(237 237 237)', borderRadius: '10px' }}
                                                                >
                                                                    Question: {item.question}
                                                                </Typography>
                                                            )}
                                                            <Typography sx={{ width: '70%', float: 'right', padding: '15px', border: '1px solid rgb(237 237 237)', borderRadius: '10px', }}>
                                                                Choices:<Typography sx={{
                                                                    padding: "10px",
                                                                    borderRadius: "8px",
                                                                    opacity: "0px",
                                                                    backgroundColor: "#F7F7F7",

                                                                }}> {item.choices} </Typography>
                                                                Answer:<Typography sx={{
                                                                    padding: "10px",
                                                                    borderRadius: "8px",
                                                                    opacity: "0px",
                                                                    backgroundColor: '#ffc773',
                                                                    width: 'fit-content'

                                                                }} variant="body1"> {item.answer}</Typography>
                                                            </Typography>

                                                        </CardContent>
                                                    </Card>

                                                ))}
                                            </div>
                                        </Box>
                                    ) : (
                                        <>
                                            {files.length !== 0 ? (
                                                <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                                            ) : (
                                                <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                                                    <Typography variant="h3">Please upload a <span className="text-decorat">txt, pdf or docx</span> file.</Typography>
                                                    <Typography className="mt-2" variant="h6">Note: Multiple files upload supported.</Typography>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>

                            {open ? (
                                <div className="spinner-container">
                                    <InfinitySpin width="200" color="#F86F03" />
                                </div>
                            ) : null}
                        </>
                    </Grid>
                    {/* </Grid> */}
                </Grid>

                {/* Vertical List of Cards */}
                <VerticalCardList items={CaseStudies} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default McqGenerator