import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { AxiosError } from "axios";
import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "react-circular-progressbar/dist/styles.css";
import './style.scss'
import { InfinitySpin } from "react-loader-spinner";
// import "./Style.scss";
import { ToastContainer, toast } from "react-toastify";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { transalationAudioUploadApi, transalationRecordingApi } from "../../api_config/api_services";
import { FileUploader } from "react-drag-drop-files";
import JapaneseToEnglishDialog from "./JapaneseToEnglishDialog";
import SelectedFiles from "../common/SelectedFiles";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";


function JapaneseToEnglish() {
  const [files, setFile] = useState<any>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [englishConversion, setEnglishConversion] = useState<any>(null);
  const [subType, setSubType] = useState("Japanese-to-English");

  const fileTypes: string[] = ["MP3", "AAC", "FLAC", "WAV", "AIFF"];
  const [audioFile, setAudioFile] = useState<any>([]);

  const [isSideVisible, setSideVisible] = useState(true);
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertColor | undefined;
    message: string | undefined;
    isVisible: boolean;
  }>();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();


  const items = [
    { id: 1, text: 'Multilingual Customer Support' },
    { id: 2, text: 'Multilingual Website Translation' },
    { id: 3, text: 'Language Learning Platforms' },
    { id: 4, text: 'E-commerce Product Descriptions Translation' },
    { id: 5, text: 'Social Media Content Translation' },
    { id: 6, text: 'Cross-Cultural Marketing Campaigns' },
    { id: 7, text: 'Media and Entertainment Subtitling' },
    { id: 8, text: 'Legal Document Translation' },
    { id: 9, text: 'Medical Record Translation' },
    { id: 10, text: 'Travel and Tourism Applications' },
    { id: 11, text: 'International Journalism and News Agencies' },
    { id: 12, text: 'International Conference Interpretation' },
    { id: 13, text: 'Academic Research Translation' },
  ]

  const CaseStudies = [
    {
      id: 1,
      title: "Enhancing Customer Reach for E-commerce Retailer",
      description: "An e-commerce retailer wanted to expand its reach to non-English speaking countries. By integrating our AI translation tool, they translated their entire product catalog and customer service communications into 10 different languages. This led to a 30% increase in international sales and a significant reduction in customer support response times, improving overall customer satisfaction."
    },
    {
      id: 2,
      title: "Streamlining Tech Support for a Global Tech Firm",
      description: "A tech firm struggled with providing timely customer support across various regions. Our AI translation tool enabled their support team to handle queries in multiple languages instantly. This resulted in faster resolution times and a more personalized customer experience, leading to a 25% improvement in customer retention rates."
    },
    {
      id: 3,
      title: "Localizing Content for a Global Educational Platform",
      description: "An online education platform aimed to provide its courses to a global audience. Using our AI translation tool, they translated course materials, quizzes, and interactive content into 15 languages. This increased enrollment rates by 40% from non-English speaking countries, making quality education accessible to a broader audience."
    }
  ]

  const notifyError = (message: string) => toast.error(message);

  const japaneseToEnglishApiCall = async (formData: any) => {
    setLoading(true)
    await transalationAudioUploadApi(subType, formData).then((result: any) => {
      if (result?.success) {
        setEnglishConversion(result?.data);
        setChatEnabled(true);
        setAudioFile([])
        setFile([])
        setOpen(false);
        setLoading(false)
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);
        setLoading(false);
      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }

  const handleAudioUpload = async () => {
    if (audioFile.length > 1) {
      notifyError("Only one file allowed at a time.");
      return;
    }
    // Check if any of the files are empty
    const emptyFile = audioFile.find((file: File) => file.size === 0);
    if (emptyFile) {
      notifyError("The uploaded file has no content.");
      return;
    }
    setOpen(true);
    const formData = new FormData();
    audioFile.forEach((file: any, index: number) => {
      formData.append(`file`, file);
    });
    formData.append('input_type', 'audio')
    formData.append('translation_type', subType)
    japaneseToEnglishApiCall(formData)

  };

  const handleUpload = async (query: any) => {
    if (query === '') {
      notifyError("Please record an audio");
    }
    else {
      setLoading(true)
      const formData = new FormData();
      formData.append('text', query)
      formData.append('input_type', 'text')
      formData.append('translation_type', subType)
      // const reqPayload: any = {
      //   text: query,
      //   input_type: 'text',
      //   translation_type: subType
      // };
      // const req = JSON.stringify(Data)
      await transalationRecordingApi(subType, formData).then((result: any) => {
        const SpeechToTextAPIData = result?.data;
        if (result?.success) {
          setResponseMessage({
            type: "success",
            message: "Success",
            isVisible: true,
          });
          setEnglishConversion(SpeechToTextAPIData);
          resetTranscript();
          setChatEnabled(true);
          setLoading(false);
        }
        else {
          const message: string = result?.data?.response?.data?.error || "Something Went Wrong."
          notifyError(message);
          setOpen(false);
          setLoading(false);
        }

      }).catch((err: any) => {
        const error = err as AxiosError;
        resetTranscript();

        // Consolidated error handling
        let errorMessage = "Something went wrong.";
        if (error.response) {
          const responseData = error.response.data as { error?: string };
          if (responseData?.error) {
            errorMessage = responseData.error;
          }
        } else {
          errorMessage = "Error occurred while setting up the request: " + error.message;
        }

        // Notify error and set response message
        notifyError(errorMessage);
        setResponseMessage({
          type: "error",
          message: errorMessage,
          isVisible: true,
        });
        setOpen(false);
        setLoading(false);
        notifyError(error.message || "something went wrong");
      })
    }
  };

  const handleMicButton = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    handleStartListening();
  };

  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: `${subType === "Japanese-to-English" ? `ja` : `en-IN`}` });
    setIsDialogOpen(true);
    setIsListening(true);
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setQuery(transcript);
    resetTranscript();
    setIsListening(false);
    setIsDialogOpen(false);
    handleUpload(transcript)
  };


  const handleCloseDialog = () => {
    setIsDialogOpen(true);
  };

  const handleChange = (fileOrFiles: any) => {
    setAudioFile([fileOrFiles[0]]);
  };
  const onDrop = (fileOrFiles: any) => {
    console.log("drop", fileOrFiles);
  };
  const onSelect = (fileOrFiles: any) => {
    setChatEnabled(false)
  };

  const onTypeError = (err = 1) => {
    console.log(err);
    notifyError(`${err}` || "File type is not supported.")

  }
  const onSizeError = (err = 1) => console.log(err);

  const handleDeletedFiles = (index: number) => {
    const arr = [...files];
    arr.splice(index, 1);
    setAudioFile(arr);
  };



  // UI Sections
  const renderIsChatEnabled = () => {

    return (
      <Box display="flex" flexDirection="column" width="80%"
      >
        {/* <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setEnglishConversion(null)
              setChatEnabled(false)
            }}
            sx={{
              textAlign: "center",
              display: "flex",
              marginTop: "20px",

              boxShadow: 0,
              color: NETSMARTZ_THEME_COLOR,
              borderRadius: 1.5,
              textTransform: "capitalize",
              fontWeight: 600,
            }}
          >
            <ArrowBackIcon
              style={{
                width: "30px",
                height: "30px",
                color: NETSMARTZ_THEME_COLOR,
                marginRight: '5px'
              }}
            />
            Back To Language Selection
          </Button>
        </div> */}
        {/* Display Conversation Messages */}
        <div className="pdf-container">
          <Card
            variant="outlined"
            sx={{ marginBottom: "10px", marginTop: '10px' }}
          >
            <CardContent>

              <Typography textAlign={"center"} marginBottom={3} variant="h4">{"Translated Text"}</Typography>
              <Typography variant="body1">{englishConversion?.translated_text}</Typography>
            </CardContent>
          </Card>

        </div>
      </Box>
    )
  }

  const renderLandingSection = () => {

    return (
      <Box display="flex" flexDirection={'column'} justifyContent="center">
        <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 3 }}>
          <Box sx={{ marginBottom: '80px' }} className="mask-box">
            <Box
              className="mask"
              style={{
                transform: `translateX(${subType === "Japanese-to-English" ? 0 : "180px"})`
              }}
            />
            <Button
              disableRipple
              variant="text"
              sx={{ color: subType === "Japanese-to-English" ? "#ffffff" : "#654320" }}
              onClick={() => {
                setSubType("Japanese-to-English")
                setQuery("")
              }
              }
            >
              Japanese-To-English
            </Button>
            <Button
              disableRipple
              variant="text"
              sx={{ color: subType === "English-to-Japanese" ? "#ffffff" : "#654320" }}
              onClick={() => {
                setSubType("English-to-Japanese")
                setQuery("")

              }}
            >
              English-To-Japanese
            </Button>
          </Box>
        </Box>
        <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
          <Typography textAlign={"center"} variant="h3">Please <span className="text-decorat">record an audio</span> or <span className="text-decorat">upload an audio file</span> {`to get the ${subType === "English-to-Japanese" ? `japanese` : `english`} translation.`}</Typography>
          <Typography sx={{ mt: 2 }} variant="h6">Note: {`Record atleast 30 words to generate the ${subType === "English-to-Japanese" ? `japanese` : `english`} translation.`}</Typography>
        </Box>
      </Box>
    )

  }


  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      {/* Header */}

      <Grid container bgcolor={'#F7F7F7'} sx={{ flex: 1, padding: 2, marginTop: '80px', }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12} borderRadius={'8px'} bgcolor={'#F7F7F7'}>
            <>
              <ToastContainer position="bottom-right" autoClose={2000} />

              <Grid container height={'78.1vh'}>
                {/* Left Grid */}
                {isSideVisible ? (
                  <Grid
                    item
                    md={2.8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius={"8px"}
                    bgcolor={'#F7F7F7'}
                    mr={1}

                    flexDirection={"column"}
                  >
                    <Box sx={{ width: "90%", mb: -1, }}>
                      <Box
                        sx={{
                          borderStyle: "dashed",
                          borderWidth: 2,
                          width: "100%",
                          borderColor: "#ccc",
                          background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                          paddingTop: 2,
                          paddingBottom: 2,
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // gap: 2,
                          // width: 278px,
                          height: "190px",
                          top: "238px",
                          left: "50px",
                          gap: "0px",
                          borderRadius: "10px",
                          // opacity: 0px,

                        }}
                      >
                        {/* <IconButton sx={{ fontSize: 30 }} onClick={() => handleMicButton()}>
                  <MicIcon sx={{ fontSize: 30 }} />
                </IconButton> */}
                        <Typography variant="h6">Record an audio</Typography>


                        <Button
                          startIcon={<MicIcon />}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleMicButton()}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: "90%",
                            boxShadow: 0,
                            gap: "0px",
                            opacity: "0px",
                            borderRadius: 1.5,
                            textTransform: "capitalize",
                            fontWeight: 600,
                            top: 40,
                            backgroundColor: "white !important", // Ensure this style is applied
                            color: "black !important",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "lightgray !important", // Optional: change color on hover
                            }
                          }}
                          disabled={audioFile.length > 0}
                        >
                          Start Recording
                        </Button>

                      </Box>
                    </Box>
                    <Typography
                      display={'flex'}
                      width={'41px'}
                      height={"41px"}
                      top={"614px"}
                      bgcolor={'black'}
                      color={"white"}
                      alignItems={'center'}
                      justifyContent={'center'}
                      textAlign={'center'}
                      borderRadius={"20px"}
                      alignSelf={"center"}
                      zIndex={99}
                      variant="body1">OR</Typography>
                    <Box sx={{ width: "90%", mt: 1 }}>
                      <FileUploader
                        className="uploader"
                        fileOrFiles={files}
                        onTypeError={onTypeError}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        onSizeError={onSizeError}
                        onDrop={onDrop}
                        onSelect={onSelect}
                        label="Upload file here"
                        dropMessageStyle={{ backgroundColor: "orange" }}
                        multiple={true}
                      >
                        <Box
                          sx={{
                            borderStyle: "dashed",
                            borderWidth: 2,
                            width: "100%",
                            borderColor: "#ccc",
                            borderRadius: 3,
                            // backgroundColor: NETSMARTZ_THEME_COLOR,
                            background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                            paddingTop: 2,
                            paddingBottom: 2,
                            flexDirection: "column",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // gap: 2,
                            height: "190px",
                            top: "238px",
                            // left: "50px",
                            gap: "0px",
                            opacity: "0px",
                            marginTop: -2
                          }}
                        >
                          <AudiotrackIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "white",
                            }}
                          />
                          <Typography align="center" variant="h6">Upload an audio file</Typography>
                        </Box>
                      </FileUploader>
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleAudioUpload}
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            marginTop: "10px",
                            width: "100%",
                            boxShadow: 0,
                            borderRadius: 1.5,
                            textTransform: "capitalize",
                            // backgroundColor: "black",
                            fontWeight: 600,
                            backgroundColor: "#3d3d3d", // Ensure this style is applied
                            color: "white",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "black !important", // Optional: change color on hover
                            }
                          }}
                          disabled={audioFile.length === 0}
                        >
                          Translate Now
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                ) : null}


                {/* Right Grid */}
                <Grid
                  item
                  md={isSideVisible ? 9 : 12}
                  display="flex"
                  className="grid-right-pane"
                  sx={{
                    backgroundColor: "white",
                    borderColor: "lightgrey",
                    // paddingTop: "0.5rem",
                    borderRadius: '8px'
                  }}
                  justifyContent="center"
                // alignItems="center"
                >
                  {isChatEnabled ? (renderIsChatEnabled()) : (
                    <>
                      {audioFile.length !== 0 ? (<SelectedFiles files={audioFile} handleDeletedFiles={handleDeletedFiles} />) : (renderLandingSection())}
                    </>
                  )}
                </Grid>
              </Grid>

              <JapaneseToEnglishDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                listening={listening}
                onStopListening={handleStopListening}
                transcript={transcript}
              />

              {loading ? (
                <div className="spinner-container">
                  <InfinitySpin width="200" color="#F86F03" />
                </div>
              ) : null}
            </>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
    </Box>
  );
}

export default JapaneseToEnglish