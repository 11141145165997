import { AxiosError } from "axios"
import { GetProductDetailsApiUrl, ProductsSearchApiUrl, articleSummarizationApiUrl, chatBotApiUrl, codeEvaluationSummaryApiUrl, codeEvaluationUserApiUrl, emailSentApiUrl, getArticlesApiUrl, huggingFaceApiUrl, langChainApiUrl, loginApiUrl, mcqApiUrl, multiTransalationDocApiUrl, multiTransalationTextApiUrl, multiTranslationApiUrl, resetPasswordApiUrl, signupApiUrl, speechToTextApiUrl, technicalInterviewApiUrl, technicalInterviewSolutionApiUrl, translationApiUrl } from "../apiConfigUrls"
import { axiosHeader, axiosMultiPartHeader, getRequest, postRequest } from "../axiosConfig"


export const huggingFaceApi = async (payload: any) => {
  try {
    const response = await postRequest(huggingFaceApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const mcqGenerateApi = async (payload: any) => {
  try {
    const response = await postRequest(mcqApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const technicalInterviewApi = async (payload: any) => {
  try {
    const response = await postRequest(technicalInterviewApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}
export const technicalInterviewSolutionApi = async (payload: any) => {
  try {
    const response = await postRequest(technicalInterviewSolutionApiUrl, payload, axiosHeader)
    const data = response.data;
    return data;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
}

export const codeEvaluationUserApi = async (payload: any) => {
  try {
    const response = await postRequest(codeEvaluationUserApiUrl, payload, axiosHeader)
    const data = response.data;
    return data;
  } catch (err) {
    const error = err as AxiosError;
    return error;
  }
}

export const codeEvaluationSummaryApi = async (payload: any) => {
  try {
    const response = await postRequest(codeEvaluationSummaryApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}



export const speechToTextApi = async (payload: any) => {
  try {
    const response = await postRequest(speechToTextApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}


export const langChainApi = async (payload: any) => {
  try {
    const response = await postRequest(langChainApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}


export const GetAllArticlesApi = async () => {
  try {
    const response = await getRequest(getArticlesApiUrl, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const ArticleSummrizationApi = async (payload: any) => {
  try {
    const response = await postRequest(articleSummarizationApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const ProductsSearchApi = async (payload: any) => {
  try {
    const response = await postRequest(ProductsSearchApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}
export const GetProductDetailsApi = async (payload: any) => {
  try {
    const response = await postRequest(GetProductDetailsApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}


export const transalationAudioUploadApi = async (transalation_type: string, payload: any) => {
  try {
    const response = await postRequest(translationApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const multiTransalationAudioUploadApi = async (payload: any) => {
  try {
    const response = await postRequest(multiTranslationApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const multiTransalationDocUploadApi = async (payload: any) => {
  try {
    const response = await postRequest(multiTransalationDocApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}


export const multiTransalationTextApi = async (payload: any) => {
  try {
    const response = await postRequest(multiTransalationTextApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const transalationRecordingApi = async (transalation_type: string, payload: any) => {
  try {
    const response = await postRequest(translationApiUrl, payload, axiosMultiPartHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const loginApi = async (payload: any) => {
  try {
    const response = await postRequest(loginApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const tagsApi = async (username:any) => {
  try {
    const response = await getRequest(`https://aibackend.netsmartz.us/auth/tags?email=${username}`, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}


export const signUpApi = async (payload: any) => {
  try {
    const response = await postRequest(signupApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const emailSentApi = async (payload: any) => {
  try {
    const response = await postRequest(emailSentApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}



export const resetPasswordApi = async (payload: any) => {
  try {
    const response = await postRequest(resetPasswordApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}

export const chatBotApi = async (payload: any) => {
  try {
    const response = await postRequest(chatBotApiUrl, payload, axiosHeader)
    const data = response.data;
    const resultObj = { success: true, data: data }
    return resultObj;
  } catch (err) {
    const error = err as AxiosError;
    const errorObj = { success: false, data: error }
    return errorObj;
  }
}