import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

interface Props {
  image?: string;
  title?: string;
  descriptions?: string;
  poweredBy?: string;
  onCardClick?: () => void;
}

const ProductsCardList = (prop: Props) => {
  const { onCardClick, image, title, descriptions, poweredBy } = prop;
  return (
    // <Card sx={{ maxWidth: 345 }}>
    <Card
      className=""
      sx={{
        ":hover": {
          boxShadow: 20,
        },
        boxShadow: 0,
        borderRadius: 3,
        padding: 2,
        position: 'relative'
      }}
    >
      <CardActionArea sx={{ marginBottom: '32px' }} onClick={onCardClick}>
        {image &&
          <CardMedia
            component="img"
            height="120 "
            image={image === 'nan' || image === 'NaN' ? "" : image}
            sx={{ borderRadius: 2, objectFit: "contain" }}
            alt="green iguana"
          />}
        <CardContent>
          {title &&
            <Typography
              gutterBottom
              variant="body2"
              fontWeight={"600"}
              component="div"
            >
              {title === 'nan' || title === 'NaN' ? "" : title}
            </Typography>}
          {descriptions &&
            <Typography sx={{ overflow: 'hidden', overflowY: 'hidden', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }} variant="body2" color="text.secondary">
              {descriptions === 'nan' || descriptions === 'NaN' ? "" : descriptions}
            </Typography>}

        </CardContent>

      </CardActionArea>
      {poweredBy &&
        <p style={{ textAlign: 'end', fontStyle: 'italic', color: 'grey', padding: '10px', position: 'absolute', bottom: '5px', right: '5px' }}>Powered by <span style={{ fontWeight: 600 }}>{poweredBy}</span></p>
      }
    </Card>
  );
};

export default ProductsCardList;
