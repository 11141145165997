import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Box, DialogActions, DialogContent, Typography } from "@mui/material";
import styled from "@emotion/styled";

import MISButton from "../Mockinterview/InterviewQues/MISButton";
import MISDialog from "../Mockinterview/InterviewQues/MISDialog";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";
const StyledTypography = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 600,
  wordWrap: "inherit",
  whiteSpace: "nowrap",
  width: "inherit",
}));

export const StyledBox = ({ heading, def }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "start", margin: "0px 10px", alignItems: "baseline" }}>
      <StyledTypography>{heading}</StyledTypography>
      <Typography variant="body1" sx={{ color: BACKGROUND.black, fontWeight: 500, margin: "0px 10px" }}>
        {def}
      </Typography>
    </Box>
  );
};
StyledBox.propTypes = {
  heading: PropTypes.string,
  def: PropTypes.string,
};

const InstructionsModal = ({ openInstructionModal = false, handleInstructionModal = noop }) => (
  <MISDialog open={openInstructionModal} sx={{ width: "500px" }}>
    <DialogContent sx={{ width: "80vw" }}>
      <Typography variant="h6" sx={{ color: NETSMARTZ_THEME_COLOR, fontWeight: 600, margin: "0px 10px", p: "10px 0px" }}>
        {"Starting Your Code Evaluation."}
      </Typography>
      <StyledBox heading="Begin Interview:" def="Click the 'Start' button and complete the following fields to generate the coding problem statement." />
      <StyledBox
        heading="Question Page:"
        def="You will be presented with a coding question that you need to complete a given time frame."
      />

      <Typography variant="h6" sx={{ color: NETSMARTZ_THEME_COLOR, fontWeight: 600, margin: "0px 10px", p: "10px 0px" }}>
        {"Final Steps"}
      </Typography>

      <StyledBox
        heading="Submit Interview:"
        def="Once you have Completed your answers, upload the txt file and click the 'Submit' button to complete your session."
      />
      <StyledBox
        heading="Receive Feedback:"
        def="After submission, your answers will be reviewed, and you will receive feedback to help you improve your interview skills."
      />
    </DialogContent>

    <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
      <MISButton variant='' onClick={handleInstructionModal} sx={{ fontSize: "16px", backgroundColor: '#EE851E !important', textAlign:'center', paddingTop:'5px !important' }}>
        {"Got it !"}
      </MISButton>
    </DialogActions>
  </MISDialog>
);

InstructionsModal.propTypes = {
  openInstructionModal: PropTypes.bool,
  handleInstructionModal: PropTypes.func,
};

export default InstructionsModal;
