import ClearIcon from "@mui/icons-material/Clear";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  AlertColor,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";
import SelectedFiles from "../common/SelectedFiles";
import { NETSMARTZ_THEME_COLOR } from "../../utils/theme/colors";


const fileTypes: string[] = ["PDF", "CSV"];
function PrivateChatGPT() {
  const [files, setFile] = useState<any>([]);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [questionAnswer, setQuestionAnswer] = useState<any>([]);
  const [isEmptyMessage, setEmptyMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertColor | undefined;
    message: string;
    isVisible: boolean;
  }>({ type: undefined, message: "", isVisible: false });

  const scrollBottom = useRef<any>(null);
  const scrollToBottom = () => {
    const lastChildElement = scrollBottom.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnackClose = () => {
    setResponseMessage({ type: undefined, message: "", isVisible: false });
  };
  useEffect(() => {
    scrollToBottom();
  }, [questionAnswer]);

  const handleChange = (fileOrFiles: any) => {
    setFile((files: any) => [...files, ...fileOrFiles]);
  };
  const onDrop = (fileOrFiles: any) => {};
  const onSelect = (fileOrFiles: any) => {};

  const onTypeError = (err = 1) => console.log(err);
  const onSizeError = (err = 1) => console.log(err);

  const handleUpload = async () => {
    if (files.length === 0) {
      console.log("No files to upload.");
      notifyfileUpload("No files to upload");
      return;
    }
    // Setting loader true
    setOpen(true);
    const formData = new FormData();
    files.forEach((file: any, index: number) => {
      formData.append(`file`, file);
    });

    try {
      const res = await axios.post(
        "http://127.0.0.1:5000/load_document",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Upload successful", res);
      try {
        await axios.post("http://127.0.0.1:5000/upload");

        setChatEnabled(true);
        setOpen(false);
      } catch (err) {
        const error = err as AxiosError;
        notifyNetworkError(error.message);
        setOpen(false);
      }
    } catch (err) {
      const error = err as AxiosError;
      notifyNetworkError(error.message);
      setOpen(false);
    }
  };

  const handleInputChange = (event: any) => {
    setMessage(event.target.value);
  };

  // this function handles the button click when messages are typed in the input
  const handleButtonClick = async () => {
    if (message === "") {
      notifyEmptyMessage("Message can't be empty");
      return;
    }

    setOpen(true);
    const data = {
      query: message,
    };
    try {
      const res = await axios.post("http://127.0.0.1:5000/ask_QA", data);
      const answer = res.data?.Answer || "";
      setQuestionAnswer((data: any) => [
        ...data,
        { ques: message, ans: answer },
      ]);

      setOpen(false);
      setMessage("");
    } catch (error) {
      const err = error as AxiosError;
      setOpen(false);
    }
  };

  const handleDeletedFiles = (index: number) => {
    const arr = [...files];
    arr.splice(index, 1);
    setFile(arr);
  };

  const notifyfileUpload = (message: string) => toast.error(message);
  const notifyEmptyMessage = (message: string) => toast.error(message);
  const notifyNetworkError = (message: string) => toast.error(message);

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={2000} />
      <Grid container className="gpt-container">
        {/* Left Grid */}
        <Grid
          item
          md={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="grid-left-pane"
          sx={{
            background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
            borderRadius: '8px',
            mr: 2
        }}
        >
          <Box sx={{ width: "80%" }}>
            <FileUploader
              className="uploader"
              fileOrFiles={files}
              onTypeError={onTypeError}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              onSizeError={onSizeError}
              onDrop={onDrop}
              onSelect={onSelect}
              label="Upload file here"
              dropMessageStyle={{ backgroundColor: "orange" }}
              multiple={true}
            >
              <Box
                sx={{
                  borderStyle: "dashed",
                  borderWidth: 2,
                  width: "100%",
                  borderColor: "#ccc",
                  borderRadius: 3,
                  backgroundColor: "#fff",
                  paddingTop: 5,
                  paddingBottom: 5,
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <PictureAsPdfRoundedIcon
                  style={{
                    width: "60px",
                    height: "60px",
                    color: NETSMARTZ_THEME_COLOR,
                  }}
                />
                <Typography variant="subtitle1">Upload file</Typography>
              </Box>
            </FileUploader>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpload}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  marginTop: "20px",
                  width: "100%",
                  boxShadow: 0,
                  color: "white",
                  borderRadius: 1.5,
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Upload file
              </Button>
            </div>
          </Box>
        </Grid>
        {/* Right Grid */}
        <Grid
          item
          md={9}
          className="grid-right-pane"
          display="flex"
          sx={{
            borderLeft: 0.5,
            borderColor: "white",
            paddingTop: "2rem",
            backgroundColor: "white",
          }}
          justifyContent="center"

          // alignItems="center"
        >
          {isChatEnabled ? (
            <Stack width={"97%"}>
              <Box
                ref={scrollBottom}
                // sx={{ width: "100%", padding: "0 16px" }}
                className="box-container"
              >
                {questionAnswer.map(
                  (data: { ques: string; ans: string }, index: number) => (
                    <div key={index}>
                      <Card
                        sx={{
                          marginBottom: 2,
                          ":hover": {
                            boxShadow: 7,
                          },
                        }}
                      >
                        <CardHeader
                          sx={{
                            backgroundColor: "rgba(0,0,0,0.1)",
                          }}
                          titleTypographyProps={{ variant: "h6" }}
                          avatar={
                            <Avatar
                              sx={{ bgcolor: "darkgray" }}
                              aria-label="recipe"
                            >
                              Q
                            </Avatar>
                          }
                          title={data.ques}
                        />

                        <CardContent>
                          <Typography>{data.ans}</Typography>
                        </CardContent>
                      </Card>
                    </div>
                  )
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Send a message"
                    sx={{ width: "90%" }}
                    value={message}
                    onKeyDown={(ev) => {
                      if (ev.key === "Enter") {
                        handleButtonClick();
                      }
                    }}
                    onChange={handleInputChange}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      boxShadow: 0,
                      borderRadius: 2,
                    }}
                    onClick={handleButtonClick}
                  >
                    <SendRoundedIcon style={{ color: "white" }} />
                  </Button>
                </Box>
              </Box>
            </Stack>
          ) : (
            <>
              {files.length !== 0 ? (
                <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h3">
                    {"Please upload a pdf file"}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {open ? (
        <div className="spinner-container">
          <InfinitySpin width="200" color="#F86F03" />
        </div>
      ) : null}
    </>
  );
}

export default PrivateChatGPT;
