import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  AlertColor,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import "./HuggingFace.scss";
import { ToastContainer, toast } from "react-toastify";
import { huggingFaceApi } from "../../api_config/api_services";
import SelectedFiles from "../common/SelectedFiles";
import useFileUpload from "../../utils/hooks/useFileUpload";
import BackButton from "../common/BackButton";
import HorizontalList from "../horizontalList/HorizontalList";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";

const fileTypes: string[] = ["TXT", "PDF", "DOCX"];

function HuggingFaceAPI() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<any>(null);
  const [showPositiveDiv, setshowPositiveDiv] = useState(false);
  const [showNegativeDiv, setshowNegativeDiv] = useState(false);

  const [isSideVisible, setSideVisible] = useState(true);
  const [responseMessage, setResponseMessage] = useState<{
    type: AlertColor | undefined;
    message: string | undefined;
    isVisible: boolean;
  }>();

  const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

  const notifyError = (message: string) => toast.error(message);

  const apiCallSentimentalAnalysis = async (formData: any) => {
    await huggingFaceApi(formData).then((res: any) => {
      const huggingFaceAPIData = res?.data;
      if (res?.success) {
        setResponseMessage({
          type: "success",
          message: "Success",
          isVisible: true,
        });
        setMessage(huggingFaceAPIData);
        setSideVisible(false);
        setChatEnabled(true);
        setOpen(false);
      }
      else {
        const message: string = res.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);
      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      setResponseMessage({
        type: "error",
        message: error.message,
        isVisible: true,
      });
      setOpen(false);
      notifyError(error.message || "something went wrong");
    })
  }
  const handleUpload = async () => {
    if (files.length === 0) {
      notifyError("No files uploaded.");
      return;
    }

    // Check if any of the files are empty
    const emptyFile = files.find((file: any) => file.size === 0);
    if (emptyFile) {
      notifyError("The uploaded file has no content.");
      return;
    }

    setOpen(true);
    const formData = new FormData();
    files.forEach((file: any, index: number) => {
      formData.append(`file`, file);
    });

    apiCallSentimentalAnalysis(formData)
  };

  const handlePositiveWords = () => {
    setshowPositiveDiv(!showPositiveDiv); // Toggles the state
  };

  const handleNegativeWords = () => {
    setshowNegativeDiv(!showNegativeDiv); // Toggles the state
  };
  const handleBack = () => {
    setChatEnabled(false)
    setSideVisible(true);
    setFiles([])
  }

  const renderResultSection = () => {
    return (<div className="new_container">
      <Grid item md={6} className="grid-item">
        <div className="summary-container">
          <div className="circular-progress-container">
            {/* Postive */}

            <CircularProgressbar
              value={Math.floor(message?.positive_percentage || 0)}
              text={`${Math.floor(message?.positive_percentage || 0)}%`}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "round",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: `#23C552`,
                textColor: "#23C552",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
          <div className="inner-card-container">
            <div>
              <Typography
                variant="h4" gutterBottom mt={3} ml={2}>
                {"Positive Words"}
              </Typography>
            </div>
            <div>
              {message && message?.positive_words?.length > 0 &&
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePositiveWords}
                  sx={{
                    width: "50% !important",
                    ml: 2,
                  }}
                  className="upload-button"
                >
                  {showPositiveDiv ? 'Hide Words' : "See Words"}
                </Button>
              }
              {showPositiveDiv && (
                <div>
                  <Typography ml={3} variant="subtitle2" gutterBottom>
                    {message?.positive_words.map((item: any, index: number) => (
                      <div key={index}>{item}</div>
                    )) || "Not found"}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>

      <Grid item md={6} className="grid-item" flexDirection={"column"}>
        <div className="summary-container">
          <div className="circular-progress-container">
            <CircularProgressbar
              value={Math.floor(message?.neutral_percentage || 0)}
              text={`${Math.floor(message?.neutral_percentage || 0)}%`}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "round",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: ` #3e98c7`,
                textColor: " #3e98c7",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
          <div className="inner-card-container">
            <div>
              <Typography
                variant="h4"
                mt={3}
                gutterBottom
                ml={9}
              >{`Neutral`}</Typography>
            </div>
          </div>
        </div>
      </Grid>


      <Grid item md={6} className="grid-item" flexDirection={"column"}>
        <div className="summary-container">
          <div className="circular-progress-container">
            <CircularProgressbar
              value={Math.floor(message?.negative_percentage || 0)}
              text={`${Math.floor(message?.negative_percentage || 0)}%`}
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: "round",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: ` #F84F31`,
                textColor: " #F84F31",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
          <div className="inner-card-container">
            <div>
              <Typography
                variant="h4"
                mt={3}
                gutterBottom
                ml={3}
              >{`Negative Words`}</Typography>
            </div>
            <div>
              {message?.negative_words?.length > 0 &&
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNegativeWords}
                  sx={{
                    width: "50% !important",
                    ml: 4,
                  }}
                  className="upload-button"
                >
                  {showNegativeDiv ? 'Hide Words' : "See Words"}
                </Button>}
              {showNegativeDiv && (
                <div>
                  <Typography ml={5} variant="subtitle2" gutterBottom>
                    {message?.negative_words.map((item: any, index: number) => (
                      <div key={index}>{item}</div>
                    )) || "Not found"}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </Grid>
    </div>
    )
  }

  const items = [
    { id: 1, text: "Customer Service Feedback Analysis" },
    { id: 2, text: "Social Media Monitoring" },
    { id: 3, text: "Brand Reputation Management" },
    { id: 4, text: "Product Reviews Analysis" },
    { id: 5, text: "Market Research" },
    { id: 6, text: "Employee Sentiment Analysis" },
    { id: 7, text: "Political Campaign Monitoring" },
    { id: 8, text: "Financial Market Sentiment Analysis" },
    { id: 9, text: "Healthcare Patient Feedback Analysis" },
    { id: 10, text: "News Article Sentiment Classification" },
    { id: 11, text: "Competitive Analysis" },
    { id: 12, text: "Crisis Management" },
    { id: 13, text: "Movie and Entertainment Reviews" },
    { id: 14, text: "Real-time Sentiment Tracking in Live Events" },
    { id: 15, text: "Public Opinion Surveys" },
    { id: 16, text: "Educational Feedback Systems" },
    { id: 17, text: "Sentiment-based Chatbot Responses" },
    { id: 18, text: "Consumer Behavior Prediction" },
    { id: 19, text: "Content Moderation" },
    { id: 20, text: "Investor Sentiment Analysis" },
  ]


  const CaseStudies = [
    {
      id: 1,
      title: "Enhancing Customer Satisfaction for E-commerce Giant",
      description: "Our sentimental analysis tool empowered a leading e-commerce platform to gauge customer satisfaction in real-time. By analyzing customer feedback across various channels, the tool identified key pain points and sentiments, enabling the company to swiftly address issues and improve overall user experience, resulting in increased customer satisfaction and retention."
    },
    {
      id: 2,
      title: "Improving Patient Experience in Healthcare",
      description: "Our sentimental analysis solution revolutionized patient experience in healthcare by analyzing feedback from patient surveys and online reviews. By understanding patient sentiments, healthcare providers could identify areas for improvement, enhance communication, and personalize care, ultimately leading to higher patient satisfaction scores and improved healthcare outcomes."
    },
    {
      id: 3,
      title: "Optimizing Brand Perception for Global Beverage Company",
      description: "Our AI-powered sentimental analysis tool enabled a global beverage company to monitor social media sentiment around their brand and products. By analyzing their social media mentions, the tool provided actionable insights into consumer perceptions, allowing the company to tailor marketing strategies, address concerns, and enhance brand reputation across diverse demographics and regions."
    },
    {
      id: 4,
      title: "Enhancing Brand Loyalty for Fashion Retailer",
      description: "Our AI - driven sentimental analysis tool enabled a fashion retailer to monitor brand sentiment and customer feedback across social media, online reviews, and forums.By analyzing sentiments expressed by customers, the tool identified brand advocates, addressed negative feedback promptly, and tailored marketing campaigns to resonate with target demographics, leading to increased brand loyalty and customer retention."
    },
  ]



  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      {/* Header */}

      <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12} borderRadius={'8px'}>
            <>
              <ToastContainer position="bottom-right" autoClose={2000} />
              <Grid container height={'78.1vh'}>

                {/* Left Grid */}
                {isSideVisible ? (
                  <Grid
                    md={2.8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // className="grid-left-pane"
                    // bgcolor={NETSMARTZ_THEME_COLOR}
                    sx={{
                      background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                      borderRadius: '8px',
                    }}
                    borderRadius={"8px"}
                    mr={1}
                  >
                    <Box sx={{ width: "80%" }}>
                      <FileUploader
                        className="uploader"
                        fileOrFiles={files}
                        onTypeError={onTypeError}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        onSizeError={onSizeError}
                        onDrop={onDrop}
                        onSelect={onSelect}
                        label="Upload file here"
                        dropMessageStyle={{ backgroundColor: "orange" }}
                      // multiple={true}
                      >
                        <Box className="upload-box" >
                          <FileCopyIcon style={{
                            width: "50px",
                            height: "50px",
                            color: NETSMARTZ_THEME_COLOR,
                          }}
                          />
                          {/* <img
                    src={TxtImage}
                    alt="Icon"
                    style={{
                      width: "60px",
                      height: "60px",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  /> */}
                          <Typography variant="subtitle1">Upload file</Typography>
                        </Box>
                      </FileUploader>
                      <div>
                        <Button
                          // className="upload-button"
                          variant="contained"
                          color="secondary"
                          onClick={handleUpload}
                          disabled={files.length === 0}
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            backgroundColor: "#3d3d3d", // Ensure this style is applied
                            color: "white",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "black !important", // Optional: change color on hover
                            }
                          }}
                        >
                          Analyse Now
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                ) : null}
                <>
                  {isChatEnabled &&
                    <>
                      <div className="text">
                        {/* <BackButton   handleBack={handleBack} title='Back To File Selection' style={{ marginRight: '10px' }} /> */}
                        <Typography textAlign='center' padding='5px' sx={{borderBottom: '1px solid #d8d5d1', marginBottom:'15px'}} variant="h4">{message?.Conversation_tone ? `Conversation Tone: ${message?.Conversation_tone}` : ''}</Typography>
                      </div>
                    </>
                  }

                </>

                {/* Right Grid */}
                <Grid
                  item
                  md={isSideVisible ? 9 : 12}
                  display="flex"
                  className="grid-right-pane"
                  sx={{
                    backgroundColor: "white",
                    borderColor: "lightgrey",
                    borderRadius: '8px'
                    // paddingTop: "2rem",
                  }}
                  justifyContent="center"
                  // alignItems="center"
                >
                  {isChatEnabled ? (renderResultSection()) : (
                    <>
                      {files && files?.length !== 0 ? (
                        <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                      ) : (
                        <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                          <Typography variant="h3">Please upload a <span className="text-decorat">txt, pdf or docx</span> file.</Typography>
                          <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              {open ? (
                <div className="spinner-container">
                  <InfinitySpin width="200" color="#F86F03" />
                </div>
              ) : null}
            </>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
    </Box>
  );
}

export default HuggingFaceAPI;
