import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Card, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { GET_SIZE } from '../../utils/responsive';
import MicIcon from "@mui/icons-material/Mic";
import { NETSMARTZ_THEME_COLOR } from '../theme/colors';
import SpeechRecognitionDialog from '../speech_to_text/SpeechRecognitionDialog';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { setData } from '../../api_config/slices/technicalQuestionSlice';
import { technicalInterviewSolutionApi } from '../../api_config/api_services';
import { toast } from 'react-toastify';

const QuestionComponent = ({ handleSubmit }) => {
    const { isXs } = GET_SIZE();
    // Use a state variable to track the current question index
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [query, setQuery] = useState("");
    const dispatch = useDispatch();

    // Use the useSelector hook to access the questionDict from the Redux store
    const questionDict = useSelector((state) => state.data?.questionDict);
    const solutionDict = useSelector((state) => state?.data?.solutionDict);
    const Experience = useSelector((state) => state?.data?.experience);
    const EmployeeId = useSelector((state) => state?.data?.employeeId);


    // Get the list of questions from the questionDict
    const questions = Object.values(questionDict);


    useEffect(() => {
        if (solutionDict?.solutionDict && Object.keys(solutionDict.solutionDict).length === questions.length) {
            handleSubmit();
        }
    }, [solutionDict]);

    const handleNextClick = () => {
        if (currentQuestionIndex < questions.length) {
            // Create a new object for the questionDict with existing data
            const newQuestionDict = { ...solutionDict };
            // Update the solutionDict in the newQuestionDict
            newQuestionDict.solutionDict = {
                ...newQuestionDict.solutionDict,
                [`Q${currentQuestionIndex + 1}`]: query // Store the transcript with the corresponding key
            };
            // Dispatch an action to update the store with the newQuestionDict
            dispatch(setData({
                experience: Experience, // Assuming you don't need to update the experience here
                questionDict: questionDict,
                employeeId: EmployeeId, // Assuming you don't need to update the employeeId here
                solutionDict: newQuestionDict,
            }));
            resetTranscript();
            setQuery('')
            if (currentQuestionIndex < questions.length - 1) {
                // If there are more questions, move to the next one
                setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            }
        }
    };

    const notifyError = (message) => toast.error(message);


    // const handleSubmit = async () => {
    //     const Data = {
    //         emp_id: EmployeeId,
    //         experience: `${Experience} years`,
    //         Question_dict: questionDict,
    //         solution_dict: solutionDict?.solutionDict

    //     };
    //     console.log(Data, "Data----->")
    //     const req = JSON.stringify(Data)
    //     console.log(req, "req-------->")

    //     await technicalInterviewSolutionApi(req).then((result) => {
    //         const technicalInterviewSolutionAPIData = result;

    //         // setMessage(technicalInterviewSolutionAPIData);
    //         // setChatEnabled(true)
    //         // setSideVisible(true)
    //         // setisFormVisible(false)
    //         // setOpen(false);
    //         console.log(technicalInterviewSolutionAPIData, "technicalInterviewAPIData----->")

    //     }).catch((err) => {
    //         const error = err;

    //         // Consolidated error handling
    //         let errorMessage = "Something went wrong.";
    //         if (error.response) {
    //             const responseData = error.response.data.error;
    //             if (responseData?.error) {
    //                 errorMessage = responseData.error;
    //             }
    //         } else {
    //             errorMessage = "Error occurred while setting up the request: " + error.message;
    //         }

    //         // Notify error and set response message
    //         // notifyError(errorMessage);
    //         // setResponseMessage({
    //         //     type: "error",
    //         //     message: errorMessage,
    //         //     isVisible: true,
    //         // });
    //         // setOpen(false);
    //     })

    // };


    // Display the current question
    const currentQuestion = questions && questions[currentQuestionIndex];

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition();

    const handleStartListening = () => {
        if (!isMicrophoneAvailable) {
            notifyError("Microphone is not available.");
            return;
        }
        if (!browserSupportsSpeechRecognition) {
            notifyError("Feature not supported in this browser.");
            return;
        }
        SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
        setIsDialogOpen(true);
        setIsListening(true);
    };

    const handleStopListening = () => {
        SpeechRecognition.stopListening();
        setQuery(transcript);
        resetTranscript();
        setIsListening(false);
        setIsDialogOpen(false);
        // handleUpload(transcript)
    };


    const handleCloseDialog = () => {
        setIsDialogOpen(true);
    };

    return (
        <Card
            className=""
            sx={{
                width: 700,
                boxShadow: 10,
                ":hover": {
                    boxShadow: 20,
                },
                borderRadius: 3,
                padding: 10,
            }}
        >

            <Box display={"flex"} justifyContent={'center'} >
                <Typography fontWeight={'bold'} variant="h6" gutterBottom>
                    Question {currentQuestionIndex + 1}
                </Typography>
            </Box>
            <Typography mt={3} mb={5} textAlign={"center"} variant="h6" paragraph>
                {currentQuestion}
            </Typography>
            <Box display={'flex'} justifyContent={"space-around"} textAlign={"center"}>
                <Button
                    variant="contained"
                    startIcon={
                        <MicIcon
                            sx={{
                                fontSize: "16px !important",
                                borderRadius: 50,
                                p: 0.2,
                                backgroundColor: "white",
                                color: NETSMARTZ_THEME_COLOR,
                            }}
                        />
                    }
                    sx={{
                        fontSize: 16,
                        px: 3,
                        width: isXs ? "100%" : 125,
                        minWidth: "90px",
                        py: 1.3,
                        mb: 2,
                        backgroundColor: NETSMARTZ_THEME_COLOR,
                        borderRadius: 2.1,
                        "&:hover": {
                            backgroundColor: NETSMARTZ_THEME_COLOR, // Change to your theme color
                        },
                    }}
                    onClick={handleStartListening}
                >
                    {"ANSWER"}
                </Button>

                <Button
                    disabled={query === '' && true}
                    variant="outlined"
                    onClick={handleNextClick}
                    endIcon={questions?.length - 1 !== currentQuestionIndex && <ArrowForwardIcon />}
                    sx={{
                        borderColor: NETSMARTZ_THEME_COLOR,
                        color: "black",
                        fontWeight: "bold",
                        borderRadius: 2,
                        width: isXs ? "100%" : 100,
                        fontSize: "15px",
                        px: 1.4,
                        py: 1.3,
                        mb: 2,
                        "&:hover": {
                            borderColor: NETSMARTZ_THEME_COLOR, // Change to your theme color
                        },
                    }}
                >
                    {questions?.length - 1 === currentQuestionIndex ? "Submit" : "Next"}
                </Button>

            </Box>
            <SpeechRecognitionDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                listening={listening}
                onStopListening={handleStopListening}
                transcript={transcript}
            />
        </Card>

    );
};

export default QuestionComponent;
