import React from 'react';

const DataComponent = ({ data }) => {
  const { message } = data;

  return (
    <div>
      {Object.keys(message).map(category => (
        <div key={category}>
          <h2>{category}</h2>
          <ul>
            {message[category].map(item => (
              <li key={item.id}>
                <h3>{item.title}</h3>
                <a href={item.article_link} target="_blank" rel="noopener noreferrer">
                  <img src={item.image_link} alt={item.title} width="100" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default DataComponent;
