import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';

interface Props {
    image?: string;
    title?: string;
    descriptions?: string;
    poweredBy?: string;
    onCardClick?: () => void;
    handleLinkClick?: () => void;
}

const ArticlesCardList = (prop: Props) => {
    const { handleLinkClick, onCardClick, image, title, descriptions, poweredBy } = prop;
    return (
        // <Card sx={{ maxWidth: 345 }}>
        <Card
            className=""
            sx={{
                ":hover": {
                    boxShadow: 20,
                },
                boxShadow: 0,
                borderRadius: 3,
                padding: 0.5,
                position: 'relative',
                maxHeight: '200px',
                maxWidth: '200px'
            }}
        >
            <CardActionArea onClick={onCardClick}>
                {image &&
                    <CardMedia
                        component="img"
                        height="120"
                        image={image}
                        sx={{ borderRadius: 2 }}
                        alt="green iguana"
                    />}
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="body2"
                        fontWeight={"600"}
                        component="div"
                    >
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {descriptions}
                    </Typography>

                </CardContent>

            </CardActionArea>
            {poweredBy &&
                <LinkIcon
                    onClick={handleLinkClick}
                    sx={{
                        position: 'absolute',
                        bottom: 10,
                        right: 16,
                        cursor: 'pointer',
                        transition: 'transform 0.3s',
                        ':hover': {
                            transform: 'scale(1.2)'
                        }
                    }}
                />
            }
        </Card>
    );
};

export default ArticlesCardList;
