import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "./Style.scss";
import { ArticleSummrizationApi, GetAllArticlesApi, langChainApi } from "../../api_config/api_services";
import useFileUpload from "../../utils/hooks/useFileUpload";
import SelectedFiles from "../common/SelectedFiles";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import HorizontalList from "../horizontalList/HorizontalList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import Services from "../services/Services";
import CardList from "../card_list/CardList";
import { servicesCardConstant } from "../../utils/constant";
import ArticlesCardList from "./ArticlesCardList";
import { useSelector } from "react-redux";
import ViewModeModal from "./ViewModeModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackButton from "../common/BackButton";
import { useNavigate } from "react-router-dom";


const fileTypes: string[] = ["PDF"];
function LangChainAPI() {
  const [open, setOpen] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState<any>();
  const [isSideVisible, setSideVisible] = useState(true);
  const [isEmptyMessage, setEmptyMessage] = useState(false);
  const [subType, setSubType] = useState("PDF");
  const [viewMode, setViewMode] = useState('paragraph'); // State to manage view mode
  const [articles, setArticles] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [button, setButton] = useState("left");
  const [articleIndex, setArticleIndex] = useState<any>('');
  const [type, setType] = useState<any>(1);
  const navigate = useNavigate();

  const handleBackClick = () => {
    setChatEnabled(false);
  };

  // Open the modal
  const handleOpenModal = (index: any) => {
    setModalOpen(true);
    setArticleIndex(index);
  }

  // Close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Handle view mode selection
  const handleViewModeChange = (event: any) => {
    setViewMode(event.target.value);
    if (event.target.value === 'paragraph') {
      setType(1);
    } else if (event.target.value === 'bullet') {
      setType(2);
    } else {
      setType(3);
    }
  };


  const scrollBottom = useRef<any>(null);
  const scrollToBottom = () => {
    const lastChildElement = scrollBottom.current?.lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: "smooth" });
  };

  const username = useSelector((state: any) => state?.auth?.username)

  const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

  useEffect(() => {
    scrollToBottom();
  }, [questionAnswer]);
  const email = username

  console.log(subType, "subType--->")

  const notifyError = (message: string) => toast.error(message);

  const apiCallPdfSummrization = async (formData: any) => {
    await langChainApi(formData).then((result: any) => {
      if (result?.success) {
        setQuestionAnswer(result?.data);
        setChatEnabled(true);
        setFiles([])
        setOpen(false);
        setViewMode('paragraph');
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);

      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }


  // APIS for Articles

  const apiCallArticleSummrization = async (index: any) => {
    setOpen(true)
    let data: any = {
      article_id: index,
      type: type
    }
    let req = JSON.stringify(data)
    console.log(req, "req--->")
    await ArticleSummrizationApi(req).then((result: any) => {
      if (result?.success) {
        setQuestionAnswer(result?.data);
        setChatEnabled(true);
        setFiles([])
        setOpen(false);
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);

      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }

  const apiCallGetArticles = async () => {
    setOpen(true)
    await GetAllArticlesApi().then((result: any) => {
      if (result?.success) {
        console.log(result?.data, "Data----->")
        setArticles(result?.data?.message)
        setOpen(false)
      }
      else {
        const message: string = result.data?.response?.data?.error || "Something Went Wrong."
        notifyError(message);
        setOpen(false);

      }

    }).catch((err: any) => {
      const error = err as AxiosError;
      // Consolidated error handling
      let errorMessage = "Something went wrong.";
      if (error.response) {
        const responseData = error.response.data as { error?: string };
        if (responseData?.error) {
          errorMessage = responseData.error;
        }
      } else {
        errorMessage = "Error occurred while setting up the request: " + error.message;
      }

      // Notify error and set response message
      notifyError(errorMessage);
      setOpen(false);
    })
  }

  const handleUpload = async () => {
    if (files.length === 0) {
      notifyError("No file uploaded.");
      return;
    }

    if (files.length > 1) {
      notifyError("Only one file allowed at a time.");
      return;
    }

    // Check if any of the files are empty
    const emptyFile = files.find((file: any) => file.size === 0);
    if (emptyFile) {
      notifyError("The uploaded file has no content.");
      return;
    }

    setOpen(true);
    const formData = new FormData();
    files.forEach((file: any, index: number) => {
      formData.append(`file`, file);
    });
    apiCallPdfSummrization(formData)
  };
  // const handleToggleViewMode = () => {
  //   setViewMode((prevMode) => (prevMode === 'paragraph' ? 'bullet' : 'paragraph'));
  // };

  const renderChatEnabled = () => {
    return (
      <Box sx={{ width: "100%" }} className="pdf-box-container">
        <div>
          <Card sx={{ marginBottom: 2 }}>
            <CardHeader
              className="pdf-card-header"
              titleTypographyProps={{ variant: "h6" }}
              avatar={
                <Avatar sx={{ bgcolor: NETSMARTZ_THEME_COLOR }} aria-label="recipe">
                  <ArrowBackIcon
                    onClick={handleBackClick}

                  />
                  {/* <PictureAsPdfIcon /> */}
                </Avatar>
              }
              title={"Summary"}
            // action={
            //   subType !== 'PDF' && (
            //     <Button variant="outlined" onClick={handleOpenModal}>
            //       Select View Mode
            //     </Button>
            //   )
            // }
            />
            <CardContent sx={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
              {viewMode === 'both' ? (
                <Box sx={{ display: 'flex', gap: 5 }}>
                  <Box sx={{ flex: 1, mr: 1 }}>
                    <Typography variant="h6">Paragraph</Typography>
                    <Typography style={{ whiteSpace: "pre-line", marginBottom: 0 }}>
                      {subType === 'PDF' ? questionAnswer?.summary : questionAnswer?.para_sum}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">Bullet Points</Typography>
                    <Typography component="ul">
                      {questionAnswer?.bull_sum?.split('\n').map((item: any, index: any) => (
                        <Box>
                          <Typography>{item}</Typography>
                        </Box>
                      ))}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  {viewMode === 'paragraph' && button === 'left' && subType !== 'Articles' ? (
                    <Typography style={{ whiteSpace: "pre-line", marginBottom: 0 }}>
                      {questionAnswer?.summary}
                    </Typography>
                  ) : (
                    <Typography style={{ whiteSpace: "pre-line", marginBottom: 0 }}>
                      {viewMode === 'paragraph' ?
                        (subType === 'PDF' ? questionAnswer?.summary : questionAnswer?.para_sum)
                        : null // You can add alternative content or null here if needed
                      }
                    </Typography>
                  )}

                  {(viewMode === 'bullet') && (
                    <Typography component="ul">
                      {questionAnswer?.bull_sum?.split('\n').map((item: any, index: any) => (
                        // <li key={index}>{item}</li>
                        <Box>
                          <Typography>{item}</Typography>
                        </Box>
                      ))}
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </div >
      </Box >
    );
  }

  const items = [
    { id: 1, text: 'Customer Reviews Summarization' },
    { id: 2, text: 'Customer Support Ticket Summarization' },
    { id: 3, text: 'Human Resources Document Summarization' },
    { id: 4, text: 'Meeting Minutes Compilation' },
    { id: 5, text: 'Market Research Reports' },
    { id: 6, text: 'Educational Material Condensation' },
    { id: 7, text: 'Legal Document Analysis' },
    { id: 8, text: 'News Aggregation' },
    { id: 9, text: 'Medical Records Summarization' },
    { id: 10, text: 'Business Intelligence Briefs' },
    { id: 11, text: 'Patent Analysis' },
    { id: 12, text: 'Scientific Paper Summaries' },
    { id: 13, text: 'Technical Documentation Summarization' },
    { id: 14, text: 'Financial Report Analysis' },
    { id: 15, text: 'Policy Document Simplification' },
    { id: 16, text: 'Insurance Claim Summarization' },
  ]

  const CaseStudies = [
    {
      id: 1,
      title: "Customer Feedback Insights",
      description: "A consumer goods company utilized our AI summarization tool to analyze customer feedback from various channels. By generating concise summaries of customer sentiments and preferences, they gained valuable insights for product development and marketing strategies, leading to increased customer satisfaction and loyalty."
    },
    {
      id: 2,
      title: "Redefining Social Media Strategies",
      description: "Our AI summarization tool enabled a digital marketing agency to analyze social media conversations and trends efficiently. By summarizing vast amounts of social media data into actionable insights, they optimized their clients' social media strategies, leading to increased engagement and brand visibility."
    },
    {
      id: 3,
      title: "Empowering Financial Decision-Making",
      description: "A financial consultancy firm utilized our summarization tool to streamline investment research and analysis. By summarizing lengthy financial reports and market analyses, they could swiftly extract crucial information, enabling clients to make informed decisions with confidence and agility in a dynamic market landscape."
    }
  ]

  const handleLinkClick = (article_link: any) => {
    const url = article_link;
    navigator.clipboard.writeText(url).then(() => {
      alert('Article Url Copied to Clipboard.');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  useEffect(() => {
    const email = username.toLowerCase();
    const validEmails = ['gv@dyreid.no', 'shyam@dyreid.no', 'himmat.pratap@netsmartz.com', 'himmat.pratap.singh.gill@netsmartz.com', 'rahul.miglani@netsmartz.com', 'admin121@gmail.com'];

    if (email.includes('rahul') || email.includes('himmat') || email.includes('admin')) {
      setSubType("both");
      // setBoth(true)
    } else if (email.includes('shyam') || email.includes('hilde') || email.includes('gv') || email.includes('gudbrand')) {
      apiCallGetArticles();
      setSubType("Articles");
      setSideVisible(false);
    } else {
      setSubType("PDF");
    }
  }, [username]);

  const renderLandingSection = () => {
    if (subType === "both") {
      return (
        <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
          <Box height={'75vh'} display="flex" flexDirection={'column'}>
            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 3 }}>

              <Box sx={{ marginBottom: '20px', marginTop: '15px' }} className="mask-box" position="relative">
                <Box
                  className="mask"
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    transition: "transform 0.3s ease",
                    transform: `translateX(${button === "left" ? '0%' : '100%'})`,
                    backgroundColor: '#654320',
                    zIndex: 1,
                    borderRadius: '4px'
                  }}
                />
                <Button
                  disableRipple
                  variant="text"
                  sx={{
                    color: button === "left" ? "#ffffff" : "#654320",
                    zIndex: 2,
                    width: '100px'
                  }}
                  onClick={() => {
                    setButton("left");
                    setSideVisible(true);
                  }}
                >
                  PDF
                </Button>
                <Button
                  disableRipple
                  variant="text"
                  sx={{
                    color: button === "right" ? "#ffffff" : "#654320",
                    zIndex: 2,
                    width: '100px'
                  }}
                  onClick={() => {
                    apiCallGetArticles();
                    setButton("right");
                    setSideVisible(false);
                  }}
                >
                  Articles
                </Button>
              </Box>
            </Box>
            {button === 'left' ? (
              <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
                <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                  <Typography variant="h3">Please upload a <span className="text-decorat">pdf</span> file.</Typography>
                  <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
                </Box>
              </Box>
            ) : (

              <div className="scrollbar" style={{ overflowX: 'auto' }}>
                <div id="services" className="sm-container">
                  {Object.keys(articles).map(category => (
                    <div key={category}>
                      <h2 style={{ textAlign: 'center', marginBottom: '30px', marginTop: '20px' }}>{category}</h2>
                      <Grid container spacing={5} paddingBottom="10px" paddingLeft="40px" paddingRight="40px">
                        {articles[category]?.map((data: any, index: any) => (
                          <Grid
                            style={{ display: 'flex' }}
                            justifyContent="center"
                            item
                            xs={12}
                            key={index}
                            sm={6}
                            md={3}
                          >
                            <ArticlesCardList
                              key={index}
                              image={data.image_link}
                              title={data.title}
                              descriptions={''}
                              poweredBy={'true'}
                              onCardClick={() => handleOpenModal(data.id)}
                              handleLinkClick={() => handleLinkClick(data?.article_link)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Box>
        </Box>
      )
    }

    else if (subType === "PDF") {
      return (
        <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
          {subType === 'PDF' && (
            <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
              <Typography variant="h3">Please upload a <span className="text-decorat">pdf</span> file.</Typography>
              <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
            </Box>
          )}
        </Box>
      );
    }
    else {
      return (
        <Box height={'75vh'} display="flex" flexDirection={'column'} justifyContent={'center'}>
          <div className="scrollbar" style={{ overflowX: 'auto' }}>
            <div id="services" className="sm-container">
              {Object.keys(articles).map(category => (
                <div key={category}>
                  <h2 style={{ textAlign: 'center', marginBottom: '30px', marginTop: '20px' }}>{category}</h2>
                  <Grid container spacing={5} paddingBottom="10px" paddingLeft="40px" paddingRight="40px">
                    {articles[category]?.map((data: any, index: any) => (
                      <Grid
                        style={{ display: 'flex' }}
                        justifyContent="center"
                        item
                        xs={12}
                        key={index}
                        sm={6}
                        md={3}
                      >
                        <ArticlesCardList
                          key={index}
                          image={data.image_link}
                          title={data.title}
                          descriptions={''}
                          poweredBy={'true'}
                          onCardClick={() => handleOpenModal(data.id)}
                          handleLinkClick={() => handleLinkClick(data?.article_link)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </Box>
      );
    }


    // return (
    //   <Box height={'75vh'} display="flex" flexDirection={'column'}>
    //     <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 3 }}>

    //       <Box sx={{ marginBottom: subType === "PDF" ? '130px' : '20px', marginTop: '15px' }} className="mask-box">
    //         <Box
    //           className="mask"
    //           style={{
    //             transform: `translateX(${subType === "PDF" ? 0 : "180px"})`,
    //           }}
    //         />
    //         <Button
    //           disableRipple
    //           variant="text"
    //           sx={{ color: subType === "PDF" ? "#ffffff" : "#654320" }}
    //           onClick={() => (
    //             setSubType("PDF"),
    //             setSideVisible(true)
    //           )}
    //         >
    //           PDF
    //         </Button>
    //         <Button
    //           disableRipple
    //           variant="text"
    //           sx={{ color: subType === "Articles" ? "#ffffff" : "#654320" }}
    //           onClick={() => (
    //             apiCallGetArticles(),
    //             setSubType("Articles"),
    //             setSideVisible(false)
    //           )}
    //         >
    //           Articles
    //         </Button>
    //       </Box>
    //     </Box>
    //     {subType === 'PDF' ? (
    //       <Box padding="10px" marginBottom="30px" display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
    //         <Typography variant="h3">Please upload a <span className="text-decorat">pdf</span> file.</Typography>
    //         <Typography className="mt-2" variant="h6">Note: Single file upload supported.</Typography>
    //       </Box>
    //     )
    //       : (
    //         <div className="scrollbar" style={{ overflowX: 'auto' }}>
    //           <div id="services" className="sm-container">
    //             {Object.keys(articles).map(category => (
    //               <div key={category}>
    //                 <h2 style={{ textAlign: 'center', marginBottom: '30px', marginTop: '20px' }}>{category}</h2>
    //                 <Grid container spacing={5} paddingBottom="10px" paddingLeft="40px" paddingRight="40px">
    //                   {articles[category]?.map((data: any, index: any) => (
    //                     <Grid
    //                       style={{ display: 'flex' }}
    //                       justifyContent="center"
    //                       item
    //                       xs={12}
    //                       key={index}
    //                       sm={6}
    //                       md={3}
    //                     >
    //                       <ArticlesCardList
    //                         key={index}
    //                         image={data.image_link}
    //                         title={data.title}
    //                         descriptions={''}
    //                         poweredBy={'true'}
    //                         onCardClick={() => handleOpenModal(data.id)}
    //                         handleLinkClick={() => handleLinkClick(data?.article_link)}
    //                       />
    //                     </Grid>
    //                   ))}
    //                 </Grid>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       )}
    //   </Box>


    // );

  }



  return (
    <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
      {/* Header */}

      <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
        <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
          {/* Horizontal List */}
          <HorizontalList items={items} />

          {/* Chat Container */}
          {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
          <Grid item sm={8} lg={12} borderRadius={'8px'}>
            <>
              <ToastContainer position="bottom-right" autoClose={2000} />

              <Grid container height={'78.1vh'}>

                {/* Left Grid */}
                {isSideVisible ? (
                  <Grid
                    md={2.8}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // className="grid-left-pane"
                    // bgcolor={NETSMARTZ_THEME_COLOR}
                    sx={{
                      background: 'linear-gradient(to right, #FFC170, #EE851E)', // Example gradient with #EE851E
                      borderRadius: '8px',
                    }}
                    borderRadius={"8px"}
                    mr={1}
                  >
                    <Box sx={{ width: "80%" }}>
                      <FileUploader
                        className="uploader"
                        fileOrFiles={files}
                        onTypeError={onTypeError}
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        onSizeError={onSizeError}
                        onDrop={onDrop}
                        onSelect={onSelect}
                        label="Upload file here"
                        dropMessageStyle={{ backgroundColor: "orange" }}
                      // multiple={false}
                      >
                        <Box className="upload-box">
                          <PictureAsPdfRoundedIcon
                            style={{
                              width: "60px",
                              height: "60px",
                              color: NETSMARTZ_THEME_COLOR,
                            }}
                          />
                          <Typography variant="subtitle1">Upload file</Typography>
                        </Box>
                      </FileUploader>
                      <div>
                        <Button
                          // className="upload-button"
                          variant="contained"
                          color="secondary"
                          onClick={handleUpload}
                          disabled={files.length === 0}
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            backgroundColor: "#3d3d3d", // Ensure this style is applied
                            color: "white",           // Ensure this style is applied
                            '&:hover': {
                              backgroundColor: "black !important", // Optional: change color on hover
                            }
                          }}
                        >
                          Generate Summary
                        </Button>
                      </div>
                    </Box>
                  </Grid>
                ) : null}

                {/* Right Grid */}
                <Grid
                  item
                  md={isSideVisible ? 9 : 12}
                  display="flex"
                  sx={{
                    // paddingTop: "2rem",
                    backgroundColor: 'white',
                    borderRadius: '8px'
                  }}
                  justifyContent="center"
                  // alignItems="center"
                  className="grid-right-pane"
                >

                  {isChatEnabled ? (renderChatEnabled()) : (
                    <>
                      {files?.length !== 0 ? (
                        <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                      ) : (

                        <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
                          {renderLandingSection()}
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>

              {open ? (
                <div className="spinner-container">
                  <InfinitySpin width="200" color="#F86F03" />
                </div>
              ) : null}

              {isEmptyMessage ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setEmptyMessage(false);
                  }}
                >
                  Message can't be empty!
                </Alert>
              ) : null}
            </>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/* Vertical List of Cards */}
        <VerticalCardList items={CaseStudies} />
      </Grid>
      <Footer />
      <ViewModeModal
        open={modalOpen}
        onClose={handleCloseModal}
        viewMode={viewMode}
        onChange={handleViewModeChange}
        onArticleClick={() => {
          handleCloseModal()
          apiCallArticleSummrization(articleIndex)
        }
        }
      />
    </Box>
  );
}

export default LangChainAPI;
