import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
    Alert,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "./Style.scss";
import { ArticleSummrizationApi, GetAllArticlesApi, GetProductDetailsApi, ProductsSearchApi, langChainApi } from "../../api_config/api_services";
import useFileUpload from "../../utils/hooks/useFileUpload";
import SelectedFiles from "../common/SelectedFiles";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import HorizontalList from "../horizontalList/HorizontalList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import Services from "../services/Services";
import CardList from "../card_list/CardList";
import { servicesCardConstant } from "../../utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import ProductsCardList from "./ProductsCardList";


interface Product {
    // Define the product properties based on your API response
    id: number;
    name: string;
    price: number;
    // Add other properties as needed
}

const fileTypes: string[] = ["PDF"];
function ProductDetails() {
    const [open, setOpen] = useState(false);
    const [questionAnswer, setQuestionAnswer] = useState<any>();
    const [isSideVisible, setSideVisible] = useState(false);
    const [isEmptyMessage, setEmptyMessage] = useState(false);
    const [subType, setSubType] = useState("PDF");
    const [articles, setArticles] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [products, setProducts] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const scrollBottom = useRef<any>(null);
    const scrollToBottom = () => {
        const lastChildElement = scrollBottom.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: "smooth" });
    };

    const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload()

    const navigate = useNavigate();
    const location = useLocation();
    const { productDetails } = location.state
    console.log(productDetails, "props--->")

    useEffect(() => {
        scrollToBottom();
    }, [questionAnswer]);

    const notifyError = (message: string) => toast.error(message);

    const apiCallGetProductDetails = async (product_id: any) => {
        let data: any = {
            product_id: product_id
        }
        let req = JSON.stringify(data)
        console.log(req, "req--->")
        setOpen(true)
        await GetProductDetailsApi(req).then((result: any) => {
            if (result?.success) {
                console.log(result?.data, "Data----->")
                setArticles(result?.data?.message)
                setOpen(false)
                navigate(`/IntelliFind/productdetails/${product_id}`, { state: { productDetails: result?.data } });
            }
            else {
                const message: string = result.data?.response?.data?.error || "Something Went Wrong."
                notifyError(message);
                setOpen(false);

            }

        }).catch((err: any) => {
            const error = err as AxiosError;
            // Consolidated error handling
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }

            // Notify error and set response message
            notifyError(errorMessage);
            setOpen(false);
        })
    }

    const items = [
        { id: 1, text: 'E-commerce Websites' },
        { id: 2, text: 'Online Marketplaces' },
        { id: 3, text: 'Streaming Services' },
        { id: 4, text: 'News Aggregators' },
        { id: 5, text: 'Travel Booking Platforms' },
        { id: 6, text: 'Online Education Platforms' },
        { id: 7, text: 'Job Portals' },
        { id: 8, text: 'Social Media Platforms' },
        { id: 9, text: 'Recipe Websites' },
        { id: 10, text: 'Fitness and Wellness Apps' },
        { id: 11, text: 'Home Improvement and Furniture Stores' },
        { id: 12, text: 'Grocery Delivery Services' },
        { id: 13, text: 'Music Streaming Services' },
        { id: 14, text: 'Books and Literature Websites' },
        { id: 15, text: 'Gaming Platforms' },
        { id: 16, text: 'Healthcare and Telemedicine Services' },
        { id: 17, text: 'Real Estate Websites' },
        { id: 18, text: 'Automotive Sales and Services' },
        { id: 19, text: 'Event Ticketing Platforms' },
        { id: 20, text: 'Pet Supplies Stores' },
        { id: 21, text: 'Financial Services and Investment Platforms' },
        { id: 22, text: 'Beauty and Cosmetic Stores' },
        { id: 23, text: 'Hobby and Craft Stores' },
        { id: 24, text: 'Electronics and Gadgets Stores' },
    ];

    const caseStudies: any[] = [
        {
            id: 1,
            title: "Revamping E-commerce Experience for Fashion Retailer",
            description: "A leading online fashion retailer, saw a 35% increase in sales after integrating our AI-driven search and recommendation engine. Customers now enjoy personalized outfit suggestions, enhancing their shopping experience and driving higher conversion rates."
        },
        {
            id: 2,
            title: "Transforming Grocery Shopping for an Online Marketplace",
            description: "The client saw a 20% increase in average order value after implementing our AI recommendation engine. Shoppers would now receive personalized product suggestions based on their dietary preferences and purchase history, making grocery shopping more efficient and enjoyable."
        },
        {
            id: 3,
            title: "Boosting Sales for an Online Home Decor Store",
            description: "Our client saw a 25% increase in sales after integrating our smart search and recommendation engine. By analyzing customer preferences, the platform was able to deliver personalized home decor suggestions, enhancing the shopping experience and boosting conversions."
        },
        {
            id: 4,
            title: "Boosting Book Sales for an Online Bookstore",
            description: "Our client transformed its user experience with our smart search and recommendation engine, resulting in a 40% rise in customer engagement. By understanding readers’ preferences, the system was now able to offer personalized book suggestions, improving sales and customer satisfaction."
        }
    ];


    // const filteredArticles = questionAnswer.filter((article: any) =>
    //     article.title.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const fieldStyle: any = {
        fontSize: '0.600rem',
        marginBottom: '8px',
        backgroundColor: '#F7F7F7',
        borderRadius: '8px',
        margin: '10px 65px',
        width: '85%',
        // top: '10px',
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                opacity: 0.1
            },
        }
    }

    const renderLandingSection = () => {
        return (
            <Box height={'75vh'} display="flex" flexDirection={'column'}>
                <div className="scrollbar" style={{ overflowX: 'auto' }}>
                    <div id="services" className={"sm-container"}>
                        <Card sx={{ display: 'flex', margin: 'auto' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: 200, padding: 1, objectFit: 'contain' }}
                                image={"https://media.southco.com/media/catalog/product/" + productDetails[0]?.image}
                                alt={productDetails[0]?.name}
                            />
                            <Box p={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                                {productDetails[0] &&
                                    <CardContent>
                                        <Typography pb={1} component="div" variant="h5">
                                            {productDetails[0]?.name ? productDetails[0]?.name : ''}
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" component="div">
                                            {productDetails[0]?.description === 'nan' ? '' : productDetails[0]?.description}
                                        </Typography>
                                        <Typography pt={2} variant="h6" color="text.primary" component="div">
                                            {"Rs." + productDetails[0]?.price ? productDetails[0]?.price : ''}
                                        </Typography>
                                    </CardContent>
                                }
                            </Box>
                        </Card>
                        <Typography
                            color="black"
                            variant="h5"
                            fontWeight="bolder"
                            lineHeight={1}
                            textAlign='center'
                            padding='25px'
                        >
                            Recommended Products
                        </Typography>
                        <Grid container spacing={3} paddingBottom='10px !important' paddingLeft='40px !important' paddingRight='40px !important'>
                            {productDetails?.slice(1).map((data: any, index: any) => (
                                <Grid
                                    style={{ display: 'flex' }}
                                    justifyContent="center"
                                    item
                                    xs={12}
                                    key={index}
                                    sm={6}
                                    md={4}
                                >
                                    <ProductsCardList
                                        key={index}
                                        image={"https://media.southco.com/media/catalog/product/" + data?.image}
                                        title={data?.name}
                                        descriptions={data?.description}
                                        poweredBy={''}
                                        onCardClick={() => apiCallGetProductDetails(data?.id)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </Box>
        );
    };

    return (
        <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
            {/* Header */}

            <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
                <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
                    {/* Horizontal List */}
                    <HorizontalList items={items} />

                    {/* Chat Container */}
                    {/* <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#f7f7f7", pt: 4, flex: 1 }}
          > */}
                    <Grid item sm={8} lg={12} borderRadius={'8px'}>
                        <>
                            <ToastContainer position="bottom-right" autoClose={2000} />

                            <Grid container height={'78.1vh'}>



                                {/* Right Grid */}
                                <Grid
                                    item
                                    md={isSideVisible ? 12 : 12}
                                    // display="flex"
                                    sx={{
                                        // paddingTop: "2rem",
                                        backgroundColor: 'white',
                                        borderRadius: '8px'
                                    }}
                                    justifyContent="center"
                                    // alignItems="center"
                                    className="grid-right-pane"
                                >

                                    {/* {isChatEnabled ? (renderChatEnabled()) : ( */}
                                    <>
                                        {/* {files?.length !== 0 ? (
                                                <SelectedFiles files={files} handleDeletedFiles={handleDeletedFiles} />
                                            ) : ( */}

                                        <Box>
                                            {renderLandingSection()}
                                        </Box>
                                        {/* )} */}
                                    </>
                                    {/* )} */}
                                </Grid>
                            </Grid>

                            {open ? (
                                <div className="spinner-container">
                                    <InfinitySpin width="200" color="#F86F03" />
                                </div>
                            ) : null}

                            {isEmptyMessage ? (
                                <Alert
                                    severity="error"
                                    onClose={() => {
                                        setEmptyMessage(false);
                                    }}
                                >
                                    Message can't be empty!
                                </Alert>
                            ) : null}
                        </>
                    </Grid>
                    {/* </Grid> */}
                </Grid>

                {/* Vertical List of Cards */}
                <VerticalCardList items={caseStudies} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default ProductDetails;
