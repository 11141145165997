import { ThemeProvider } from "@mui/material";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import myTheme from "../utils/theme/theme";
import "./App.scss";
import { HelmetProvider } from "react-helmet-async";
import McqGenerator from "../components/mcq_generator/McqGenerator";
import SpeechToText from "../components/speech_to_text/SpeechToText";
import QaDatabase from "../components/qa_databse/QaDatabase";
import MainLayout from "../layout/main_layout/MainLayout";
import GptAPI from "../components/gpt_api/GptAPI";
import LangChainAPI from "../components/lang_chain_api/LangChainAPI";
import HuggingFaceAPI from "../components/hugging_face_api/HuggingFaceAPI";
import PineConeAPI from "../components/pine_cone_api/PineConeAPI";
import PrivateChatGPT from "../components/private_chat_gpt/PrivateChatGPT";
import Home from "../components/home/Home";
import InterviewQuestions from "../components/Mockinterview/InterviewQues";
import TechnicalInterview from "../components/technical_interview/TechnicalInterview";
import CodeEvaluationTool from "../components/code_evaluation_tool/CodeEvaluationTool";
import InterviewResponses from "../components/Mockinterview/InterviewResponses";
import User from "../components/sql_query/User";
import JapaneseToEnglish from "../components/japanese_to_english/JapaneseToEnglish";
import Login from "../components/auth/Login";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import { useEffect, useState } from "react";
import EmailSent from "../components/auth/EmailSent";
import Signup from "../components/auth/Signup";
import axios from "axios";
import ProductList from "../components/product_list/ProductList";
import ProductDetails from "../components/product_list/ProductDetails";
import MultiTranslation from "../components/multi_translation/MultiTranslation";
import Data from "../components/lang_chain_api/Data";
import Chatbot from "../components/Chatbot/Chatbot";

function App() {
  const token = useSelector((state: any) => state?.auth?.token) || localStorage.getItem('token');
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      setIsLoggedIn(true);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      setIsLoggedIn(false);
    }
  }, [token]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={myTheme}>
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="login" element={isLoggedIn ? <Navigate to="/" replace /> : <Login />} />
            <Route path="/admin/signup" element={isLoggedIn ? <Navigate to="/" replace /> : <Signup />} />
            <Route path="forgot-password" element={isLoggedIn ? <Navigate to="/" replace /> : <ForgotPassword />} />
            <Route path="reset-password/:token" element={isLoggedIn ? <Navigate to="/" replace /> : <ResetPassword />} />
            <Route path="email-sent" element={isLoggedIn ? <Navigate to="/" replace /> : <EmailSent />} />
            <Route path="data" element={<Data />} />

            {/* Private Routes */}
            <Route path="/" element={isLoggedIn ? <MainLayout /> : <Navigate to="/login" replace />} >
              <Route index element={<Home />} />
              <Route path="gpt" element={<GptAPI />} />
              <Route path="pinecone" element={<PineConeAPI />} />
              <Route path="SmartDigest" element={<LangChainAPI />} />
              <Route path="EmpathAI" element={<HuggingFaceAPI />} />
              <Route path="chat-GPT" element={<PrivateChatGPT />} />
              <Route path="SmartQuiz" element={<McqGenerator />} />
              <Route path="Transcribo" element={<SpeechToText />} />
              <Route path="GlobalSpeak" element={<JapaneseToEnglish />} />
              <Route path="ConverseAI" element={<QaDatabase />} />
              <Route path="ConverseIQ" element={<InterviewQuestions />} />
              <Route path="TechIQ" element={<TechnicalInterview />} />
              <Route path="CodeCraft" element={<CodeEvaluationTool />} />
              <Route path="ConverseIQ-responses" element={<InterviewResponses />} />
              <Route path="user" element={<User />} />
              <Route path="IntelliFind" element={<ProductList />} />
              <Route path="IntelliFind/productdetails/:id" element={<ProductDetails />} />
              <Route path="multi-translation" element={<MultiTranslation />} />
              <Route path="Chatbot" element={<Chatbot />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
