import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import {
    Alert,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState, ChangeEvent } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InfinitySpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "./Style.scss";
import {
    ArticleSummrizationApi,
    GetAllArticlesApi,
    GetProductDetailsApi,
    ProductsSearchApi,
    langChainApi
} from "../../api_config/api_services";
import useFileUpload from "../../utils/hooks/useFileUpload";
import SelectedFiles from "../common/SelectedFiles";
import VerticalCardList from "../verticalCardList/VerticalCardList";
import HorizontalList from "../horizontalList/HorizontalList";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import Services from "../services/Services";
import CardList from "../card_list/CardList";
import { servicesCardConstant } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import ProductsCardList from "./ProductsCardList";
import CloseIcon from '@mui/icons-material/Close';
import ReactSelect from "react-select";
import DropdownSearch from "./DropDownSearch";
import useDebounce from "../../hooks/useDebounce";
import { useSelector } from "react-redux";

const fileTypes = ["PDF"];
const items = [
    { id: 1, text: 'E-commerce Websites' },
    { id: 2, text: 'Online Marketplaces' },
    { id: 3, text: 'Streaming Services' },
    { id: 4, text: 'News Aggregators' },
    { id: 5, text: 'Travel Booking Platforms' },
    { id: 6, text: 'Online Education Platforms' },
    { id: 7, text: 'Job Portals' },
    { id: 8, text: 'Social Media Platforms' },
    { id: 9, text: 'Recipe Websites' },
    { id: 10, text: 'Fitness and Wellness Apps' },
    { id: 11, text: 'Home Improvement and Furniture Stores' },
    { id: 12, text: 'Grocery Delivery Services' },
    { id: 13, text: 'Music Streaming Services' },
    { id: 14, text: 'Books and Literature Websites' },
    { id: 15, text: 'Gaming Platforms' },
    { id: 16, text: 'Healthcare and Telemedicine Services' },
    { id: 17, text: 'Real Estate Websites' },
    { id: 18, text: 'Automotive Sales and Services' },
    { id: 19, text: 'Event Ticketing Platforms' },
    { id: 20, text: 'Pet Supplies Stores' },
    { id: 21, text: 'Financial Services and Investment Platforms' },
    { id: 22, text: 'Beauty and Cosmetic Stores' },
    { id: 23, text: 'Hobby and Craft Stores' },
    { id: 24, text: 'Electronics and Gadgets Stores' },
];

type CaseStudy = {
    id: number;
    title: string;
    description: string;
};

const caseStudies: CaseStudy[] = [
    {
        id: 1,
        title: "Revamping E-commerce Experience for Fashion Retailer",
        description: "A leading online fashion retailer, saw a 35% increase in sales after integrating our AI-driven search and recommendation engine. Customers now enjoy personalized outfit suggestions, enhancing their shopping experience and driving higher conversion rates."
    },
    {
        id: 2,
        title: "Transforming Grocery Shopping for an Online Marketplace",
        description: "The client saw a 20% increase in average order value after implementing our AI recommendation engine. Shoppers would now receive personalized product suggestions based on their dietary preferences and purchase history, making grocery shopping more efficient and enjoyable."
    },
    {
        id: 3,
        title: "Boosting Sales for an Online Home Decor Store",
        description: "Our client saw a 25% increase in sales after integrating our smart search and recommendation engine. By analyzing customer preferences, the platform was able to deliver personalized home decor suggestions, enhancing the shopping experience and boosting conversions."
    },
    {
        id: 4,
        title: "Boosting Book Sales for an Online Bookstore",
        description: "Our client transformed its user experience with our smart search and recommendation engine, resulting in a 40% rise in customer engagement. By understanding readers’ preferences, the system was now able to offer personalized book suggestions, improving sales and customer satisfaction."
    }
];

type Product = {
    id: string;
    name: string;
    image: string;
    description: string;
};


function ProductList() {
    const [open, setOpen] = useState<boolean | undefined>(false);
    const [questionAnswer, setQuestionAnswer] = useState<Product[]>([]);
    const [search, setSearch] = useState<boolean>(false);
    const [isDialogOpen, setisDialogOpen] = useState<boolean>(false);
    const [isEmptyMessage, setEmptyMessage] = useState<boolean>(false);
    const [subType, setSubType] = useState<string>("PDF");
    const [articles, setArticles] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [products, setProducts] = useState<Product[]>([]);
    const [page, setPage] = useState<number>(1);
    const [searchPage, setSearchPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadMore, setLoadMore] = useState<boolean>(true);
    const navigate = useNavigate();
    const scrollBottom = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);


    const { files, setFiles, isChatEnabled, setChatEnabled, handleChange, handleDeletedFiles, onDrop, onSelect, onSizeError, onTypeError } = useFileUpload();

    useEffect(() => {
        scrollBottom.current?.lastElementChild?.scrollIntoView({ behavior: "smooth" });
    }, [questionAnswer]);



    function handleKeyDown(event: any) {
        if (event.keyCode === 13) {
            if (searchTerm != '') {
                setSearch(true)
                setisDialogOpen(false)
            }
            else {
                setSearch(false)
                setQuestionAnswer([])
            }
        }
    }

    const { token } = useSelector((state: any) => state?.auth);

    const notifyError = (message: string) => toast.error(message);

    const fetchProducts = async (page: number) => {
        setOpen(true);
        try {
            const response = await axios.get(`https://southcosearch.netsmartz.us/AllProducts?page=${page}&per_page=30`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = response?.data
            console.log(response)
            setProducts(prevProducts => [...prevProducts, ...data]);
            setHasMore(data.length > 0);
        } catch (error: any) {
            console.error("Error fetching products:", error);
            notifyError(error);
        } finally {
            setOpen(false);
        }
    };


    useEffect(() => {
        fetchProducts(page);
    }, [page]);

    useEffect(() => {
        apiCallProductsSearch(searchTerm);
    }, [searchPage]);

    const loadMoreProducts = () => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };
    const loadMoreSearchProducts = () => {
        if (hasMore) {
            setSearchPage(prevPage => prevPage + 1);
            setisDialogOpen(false);
            setLoadMore(true)
        }
    };

    const apiCallProductsSearch = async (query: any) => {
        if (query === '') {
            setisDialogOpen(false);
            // setSearch(false);
            setisDialogOpen(false);
            setSearchTerm('');
            return;
        }


        let data: any = {
            query: query
        };
        let req = JSON.stringify(data);
        console.log(req, "req--->");

        try {
            const result = await axios.get(`https://southcosearch.netsmartz.us/search?query=${query}&page=${searchPage}&per_page=30 `, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = result?.data?.products
            setQuestionAnswer(loadMore ? prevProducts => [...prevProducts, ...data] : data);
            setHasMore(data?.length > 0);
            // setisDialogOpen(true);
            setOpen(false);
        } catch (err) {
            const error = err as AxiosError;
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }
            notifyError(errorMessage);
            setOpen(false);
            setSearch(false);
        } finally {
            setOpen(false);
        }
    };

    const debouncedSearch = useDebounce(apiCallProductsSearch, 200);

    const apiCallGetProductDetails = async (product_id: any) => {
        let data: any = {
            product_id: product_id
        };
        let req = JSON.stringify(data);
        console.log(req, "req--->");
        setOpen(true);
        await GetProductDetailsApi(req).then((result: any) => {
            if (result?.success) {
                console.log(result?.data, "Data----->");
                setArticles(result?.data?.message);
                setOpen(false);
                navigate(`/IntelliFind/productdetails/${product_id}`, { state: { productDetails: result?.data } });
            }
            else {
                const message: string = result.data?.response?.data?.error || "Something Went Wrong.";
                notifyError(message);
                setOpen(false);
            }
        }).catch((err: any) => {
            const error = err as AxiosError;
            let errorMessage = "Something went wrong.";
            if (error.response) {
                const responseData = error.response.data as { error?: string };
                if (responseData?.error) {
                    errorMessage = responseData.error;
                }
            } else {
                errorMessage = "Error occurred while setting up the request: " + error.message;
            }
            notifyError(errorMessage);
            setOpen(false);
        });
    };


    const fieldStyle = {
        fontSize: '0.600rem',
        marginBottom: '8px',
        backgroundColor: '#F7F7F7',
        borderRadius: '8px',
        margin: '10px 65px',
        width: '75%',
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                opacity: 0.1
            },
        }
    };


    const renderLandingSection = () => (
        <Box height={'75vh'} display="flex" flexDirection={'column'}>
            <div className="dropdown-container">
                <TextField
                    onKeyDown={(e) => { handleKeyDown(e) }}
                    label="Search"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        debouncedSearch(e.target.value)
                        setisDialogOpen(true)
                        setLoadMore(false)
                    }}
                    style={{ marginBottom: '20px' }}
                    sx={fieldStyle}
                // autoComplete="off"
                />
                <Button
                    onClick={() => {
                        if (searchTerm != '') {
                            setSearch(true)
                            setisDialogOpen(false)
                        }
                        else {
                            setSearch(false)
                            setQuestionAnswer([])
                        }

                    }}
                    style={{ marginTop: '15px', marginLeft: '-40px', padding: '10px' }}
                    sx={{
                        borderRadius: '8px',
                        width: '10%',
                    }} variant="contained" color="primary">
                    Search
                </Button>
                {isDialogOpen &&
                    <ul className="suggestions-list">
                        {questionAnswer && questionAnswer?.map((item) => (
                            <li className="suggestion-item" key={item?.id} onClick={() => { apiCallGetProductDetails(item.id) }}>
                                {item?.name}
                            </li>
                        ))}
                    </ul>
                }
            </div>

            < div className="scrollbar" style={{ overflowX: 'auto' }}>
                <div id="services" className={'sm-container'}>
                    <Typography
                        color="black"
                        variant="h5"
                        fontWeight="bolder"
                        lineHeight={1}
                        textAlign="center"
                        padding="25px"
                    >
                        Explore Our Products
                    </Typography>
                    <Grid
                        container
                        spacing={3}
                        paddingBottom="10px !important"
                        paddingLeft="40px !important"
                        paddingRight="40px !important"
                    >
                        {products?.map((data, index) => (
                            <Grid
                                style={{ display: 'flex' }}
                                justifyContent="center"
                                item
                                xs={12}
                                key={index}
                                sm={6}
                                md={4}
                            >
                                <ProductsCardList
                                    key={index}
                                    image={"https://media.southco.com/media/catalog/product/" + data?.image} // Adjust URL construction
                                    title={data?.name}
                                    descriptions={data?.description}
                                    poweredBy={''}
                                    onCardClick={() => apiCallGetProductDetails(data?.id)} // Example function call
                                />
                            </Grid>
                        ))}
                    </Grid>

                    {hasMore && products?.length > 0 && (
                        <Box display="flex" justifyContent="center" marginTop={2}>
                            <Button onClick={loadMoreProducts} variant="contained" color="primary">
                                Load More
                            </Button>
                        </Box>
                    )}
                </div>
            </div >
        </Box >
    );

    const renderSearchSection = () => (
        <Box height={'75vh'} display="flex" flexDirection={'column'}>
            <div className="dropdown-container">
                <TextField
                    onKeyDown={(e) => { handleKeyDown(e) }}
                    label="Search"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        debouncedSearch(e.target.value)
                        setLoadMore(false)
                        setSearchPage(1)
                        // setisDialogOpen(true)
                    }}
                    style={{ marginBottom: '20px' }}
                    sx={fieldStyle}
                // autoComplete="off"
                />
                <Button
                    onClick={() => {
                        if (searchTerm != '') {
                            setSearch(true)
                            setisDialogOpen(false)
                        }
                        else {
                            setSearch(false)
                            setQuestionAnswer([])
                        }

                    }}
                    style={{ marginTop: '15px', marginLeft: '-40px', padding: '10px' }}
                    sx={{
                        borderRadius: '8px',
                        width: '10%',
                    }} variant="contained" color="primary">
                    Search
                </Button>
                {isDialogOpen &&
                    <ul className="suggestions-list">
                        {questionAnswer && questionAnswer?.map((item) => (
                            <li className="suggestion-item" key={item.id} onClick={() => { apiCallGetProductDetails(item.id) }}>
                                {item.name}
                            </li>
                        ))}
                    </ul>
                }
            </div>
            <div className="scrollbar" style={{ overflowX: 'auto' }}>
                <div id="services" className={"sm-container"}>
                    <Typography
                        color="black"
                        variant="h5"
                        fontWeight="bolder"
                        lineHeight={1}
                        textAlign='center'
                        padding='25px'
                    >
                        Explore Our Products
                    </Typography>
                    <Grid container spacing={3} paddingBottom='10px !important' paddingLeft='40px !important' paddingRight='40px !important'>
                        {questionAnswer && questionAnswer?.map((data, index) => (
                            <Grid
                                style={{ display: 'flex' }}
                                justifyContent="center"
                                item
                                xs={12}
                                key={index}
                                sm={6}
                                md={4}
                            >
                                <ProductsCardList
                                    key={index}
                                    image={"https://mcstaging.montesdoggett.com/media/catalog/product/" + data.image}
                                    title={data?.name}
                                    descriptions={data?.description}
                                    poweredBy={''}
                                    onCardClick={() => apiCallGetProductDetails(data.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {hasMore && products?.length > 0 && (
                        <Box display="flex" justifyContent="center" marginTop={2}>
                            <Button onClick={loadMoreSearchProducts} variant="contained" color="primary">
                                Load More
                            </Button>
                        </Box>
                    )}
                </div>
            </div>
        </Box>
    );

    return (
        <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
            <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, marginTop: '80px' }}>
                <Grid bgcolor={'#F7F7F7'} item xs={12} lg={9} px={2}>
                    <HorizontalList items={items} />
                    <Grid item sm={8} lg={12} borderRadius={'8px'}>
                        <ToastContainer position="bottom-right" autoClose={2000} />
                        <Grid container height={'78.1vh'}>
                            <Grid
                                item
                                md={12}
                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                justifyContent="center"
                                className="grid-right-pane"
                            >
                                <Box>
                                    {search ? renderSearchSection() : renderLandingSection()}
                                </Box>
                            </Grid>
                        </Grid>
                        {open && (
                            <div className="spinner-container">
                                <InfinitySpin width="200" color="#F86F03" />
                            </div>
                        )}
                        {isEmptyMessage && (
                            <Alert
                                severity="error"
                                onClose={() => setEmptyMessage(false)}
                            >
                                Message can't be empty!
                            </Alert>
                        )}
                    </Grid>
                </Grid>
                <VerticalCardList items={caseStudies} />
            </Grid>
            <Footer />
        </Box>
    );
}

export default ProductList;
