// Home.tsx
import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, TextField, Paper, Grid, Card, CardContent, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import MicIcon from "@mui/icons-material/Mic";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { ThreeDots } from "react-loader-spinner";
import BotGIF from "../../assets/images/bot.gif";
import chatBot from "../../assets/images/chatbot.png";
import Footer from "../footer/Footer";
import { NETSMARTZ_THEME_COLOR } from "../theme/colors";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";


const Home: React.FC<any> = ({ isSideVisible, setSideVisible, conversation, setConversation  }) => {

  const [query, setQuery] = useState<any>("");
  // const [conversation, setConversation] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex]: any = useState(null);

  const [isListening, setIsListening] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChatEnabled, setChatEnabled] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [utterance, setUtterance]: any = useState(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  const notifyError = (message: string) => toast.error(message);

  const handleMicButton = () => {
    if (!isMicrophoneAvailable) {
      notifyError("Microphone is not available.");
      return;
    }
    if (!browserSupportsSpeechRecognition) {
      notifyError("Feature not supported in this browser.");
      return;
    }
    handleStartListening();
  };

  useEffect(() => {
    if (listening && transcript) {
      setQuery(transcript);
    }
  }, [listening, transcript]);


  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    setIsDialogOpen(true);
    setIsListening(true);
  };

  const handleStopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
    setIsDialogOpen(false);
    resetTranscript();
  };

  const handleStop = () => {
    setActiveIndex(null);
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsStart(false);
  };

  useEffect(() => {
    const synth = window.speechSynthesis;
    return () => {
      synth.cancel();
      handleStop()
    };
  }, []);

  const handleSpeak = (text: any, index: number) => {
    handleStop()
    setActiveIndex(index);
    setIsStart(true)
    const u: any = new SpeechSynthesisUtterance(text);
    setUtterance(u);
    const synth = window.speechSynthesis;
    synth.speak(u);

  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      //setValue(event.target.value);
      // Execute your desired function here
      if (event.target.value !== '') {
        // handleApiCall(event.target.value)
        setConversation([
          {
            user: "Hi I want to know about netsmartz?",
            bot: "No Response Received"
          },
          {
            user: "Hi I want to know about netsmartz?",
            bot: "No Response Received"
          },
          {
            user: "Hi I want to know about netsmartz?",
            bot: "No Response Received"
          },
        ]);
        console.log('You entered:', event.target.value);
        setQuery("");
      }
      else {
        console.log('You entered nothing:');
      }

    }
  }


  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      mt={9}
      sx={{
        height: '90vh',
        p: 2,
        overflow: 'hidden', // Hide scrollbars
        bgcolor: '#F7F7F7', // Background color for better contrast
      }}
    >

      {/* Left Corner Menu */}
      {!isSideVisible && (
        <Box
          position="absolute"
          top={70}
          left={0}
          p={1}
          pl={2}
          display="flex"
        // justifyContent="flex-start"
        // alignItems="flex-start"
        // width="100vw"
        >
          <IconButton onClick={() => setSideVisible(true)}>
            <MenuIcon sx={{ color: "#7e7e7e" }} />
          </IconButton>
        </Box>
      )}
      {/* Main Content */}
      <Box height={"100vh"} display="flex" flexDirection="column" bgcolor={'#F7F7F7'}>
        {/* <ToastContainer position="bottom-right" autoClose={2000} /> */}

        <Grid bgcolor={'#F7F7F7'} container sx={{ flex: 1, padding: 2, }}>
          <Grid bgcolor={'#F7F7F7'} item xs={15} lg={12} >

            {/* Chat Container */}
            <Grid >
              <Paper
                sx={{
                  padding: "20px",
                  marginLeft: isSideVisible === false ? "30px" : "0px", // Use ternary operator to handle the conditional styling
                  borderRadius: "8px",
                  minHeight: "84vh",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                }}

              >
                {/* Display Conversation */}
                <Box
                  sx={{
                    maxHeight: "calc(80vh - 120px)",
                    overflowY: "auto",
                    marginBottom: "20px",
                    "&::-webkit-scrollbar": {
                      display: "none", // Hide the scrollbar
                    },
                  }}
                >
                  {/* Display Welcome Message */}
                  {!conversation.length && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "50vh",
                        fontStyle: "italic",
                      }}
                    >
                      <Typography variant="body1" fontSize={'18px'} fontWeight={'600'} textAlign="center">
                        Welcome to ConverseAI: Your Virtual Companion
                        <Typography variant="body2"> How can I assist you today?</Typography>
                      </Typography>
                      <img
                        className="item_text"
                        src={chatBot}
                        alt="GIF"
                        style={{
                          width: "125px",
                          height: "125px",
                          marginTop: "20px",
                        }}
                      />
                    </Box>
                  )}

                  {/* Display Conversation Messages */}
                  {conversation?.map((item: any, index: any) => (
                    <Card
                      key={index}
                      variant="outlined"
                      sx={{ marginBottom: "10px", border: 'none' }}
                    >
                      <CardContent>
                        {item?.user && (
                          <Typography
                            variant="body1"
                            sx={{
                              width: '55%', marginBottom: "4px", fontWeight: "bold", padding: '15px', paddingLeft: '15px', paddingRight: '15px', backgroundColor: 'rgb(237 237 237)', borderRadius: '10px', overflow: 'auto', // Allow scroll if needed
                              wordBreak: 'break-word',
                            }}
                          >
                            Question: {item?.user}
                          </Typography>
                        )}
                        <Typography sx={{
                          width: '55%',
                          float: 'right',
                          padding: '15px',
                          border: '1px solid rgb(237, 237, 237)',
                          borderRadius: '10px',
                          overflow: 'auto', // Allow scroll if needed
                          wordBreak: 'break-word', // Ensure words break and wrap correctly
                        }} variant="body1">
                          {!loading && (isStart ? <VolumeUpIcon onClick={() => handleStop()} sx={{ color: activeIndex === index ? '#F58220' : 'black', float: 'right' }} /> : <VolumeUpIcon onClick={() => handleSpeak(item.bot, index)} sx={{ color: activeIndex === index ? '#F58220' : 'black', float: 'right' }} />)}
                          <p>{item.bot}</p>

                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Box>

                {/* Input Textfield and Button */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "30px",
                    width: "97%",
                    borderTop: "1px solid #d3d3d3",
                    pt: 3,
                  }}
                >
                  <Box
                    width={"95%"}
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                      backgroundColor: "white",
                    }}
                  >
                    <TextField
                      placeholder="Enter your query"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={handleKeyDown}
                      fullWidth
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#B2BAC2",
                          },
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            border: "0.1px solid #B2BAC2",
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {loading ? (
                              <IconButton>
                                <ThreeDots height={30} width={30} color={'NETSMARTZ_THEME_COLOR'} />
                              </IconButton>
                            ) : (
                              <>
                                <IconButton >
                                  <SendIcon />
                                </IconButton>
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box bgcolor={NETSMARTZ_THEME_COLOR} ml={1} borderRadius={'5px'}>
                      {!listening ? (
                        <IconButton onClick={handleMicButton} >
                          <MicIcon sx={{ color: 'white' }} />
                        </IconButton>
                      ) : (
                        <IconButton onClick={handleStopListening} >
                          <StopCircleIcon sx={{ color: 'white' }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Box>


    </Grid>
  )
};

export default Home;
